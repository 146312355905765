import { GET_ALL_USERS } from "../actions/AdminAction";

const initialState = {
  allUsers: null,
};

function adminReducer(state = initialState, action) {
  console.log("admin_reducer");
  switch (action.type) {
    case GET_ALL_USERS:
      console.log("In_admin_switch_case", action.payload.data);
      return { ...state, allUsers: action.payload.data };
    default:
      return state;
  }
}

export default adminReducer;
