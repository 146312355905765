import { USER_LOGIN_ACTION } from "../actions/UserAction";

const initialState = {
  tokenData: null,
  isLoggedIn: false,
};

function UserReducer(state = initialState, action) {
  console.log("in_ll_reducer");
  switch (action.type) {
    case USER_LOGIN_ACTION:
      console.log("in_login_", action.payload.token);
      return { ...state, tokenData: action.payload.token, isLoggedIn: true };
    default:
      return state;
  }
}

export default UserReducer;
