import { GET_BOOKS } from '../actions/BookAction';

const initialState = {
  books: [],
  bookmarks: []
};

function booksReducer(state = initialState, action) {
  console.log('books_reducer')
  switch (action.type) {
    case GET_BOOKS:
      console.log('In_right_switch_case');
      return { ...state, books: action.payload };
    default:
      return state;
  }
}

export default booksReducer;