import React from "react";
import logo from "../assets/img/logo2.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ImExit, ImHome } from "react-icons/im";
import { TbReportAnalytics } from "react-icons/tb";
import { TiChartBarOutline } from "react-icons/ti";
import { FiUsers } from "react-icons/fi";
import { BiAlarmSnooze, BiTransferAlt } from "react-icons/bi";
import { logOutApi } from "../redux/actions/AuthAction";

export default function Sidebar() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state?.authReducer);

  const logOut = () => {
    dispatch(logOutApi());
    window.location.reload();
  };

  return (
    <div
      style={{
        width: window.innerWidth > 1400 ? "15vw" : "20vw",
        height: "100vh",
      }}
    >
      <div className="sidebar-container flex-col">
        <div
          onClick={() => {
            navigate("/");
          }}
          style={{
            width: "100%",
            paddingBottom: "10px",
          }}
        >
          <img
            src={logo}
            alt=""
            style={{
              height: "25px",
            }}
          />
        </div>
        {/* <div
          className="text-lg uppercase text-slate-500 font-semibold mt-10 pb-2"
          style={{ borderBottom: "1px solid gray" }}
        >
          Pages
        </div> */}
        <ul className="flex flex-col mt-5">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? " text-yellow" : " text-c-black"
              }
            >
              <ImHome size={20} color="gray" className="mr-2" />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/customers"
              className={({ isActive }) =>
                isActive ? " text-yellow" : " text-c-black"
              }
            >
              <FiUsers size={20} color="gray" className="mr-2" />
              Customers
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/pending"
              className={({ isActive }) =>
                isActive ? " text-yellow" : " text-c-black"
              }
            >
              <TiChartBarOutline size={20} color="gray" className="mr-2" />
              Pending
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/snooze"
              className={({ isActive }) =>
                isActive ? " text-yellow" : " text-c-black"
              }
            >
              <BiAlarmSnooze size={20} color="gray" className="mr-2" />
              Snooze
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/report"
              className={({ isActive }) =>
                isActive ? " text-yellow" : " text-c-black"
              }
            >
              <TbReportAnalytics size={20} color="gray" className="mr-2" />
              Collections Report
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/bt-report"
              className={({ isActive }) =>
                isActive ? " text-yellow" : " text-c-black"
              }
            >
              <TbReportAnalytics size={20} color="gray" className="mr-2" />
              Bucket Report
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/reports"
              className={({ isActive }) =>
                isActive ? " text-yellow" : " text-c-black"
              }
            >
              <TbReportAnalytics size={20} color="gray" className="mr-2" />
              Reports
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/transactions"
              className={({ isActive }) =>
                isActive ? " text-yellow" : " text-c-black"
              }
            >
              <BiTransferAlt size={20} color="gray" className="mr-2" />
              Transactions
            </NavLink>
          </li>
          {user?.is_admin ? (
            <li>
              <NavLink
                to="/users"
                className={({ isActive }) =>
                  isActive ? " text-yellow" : " text-c-black"
                }
              >
                <FiUsers size={20} color="gray" className="mr-2" />
                Users
              </NavLink>
            </li>
          ) : null}
          <li
            className="text-c-black mt-2"
            onClick={() => {
              window.confirm("Do you want to log Out")
                ? logOut()
                : console.log("no");
            }}
          >
            <ImExit size={20} color="gray" className="mr-2" />
            Log Out
          </li>
        </ul>
      </div>
    </div>
  );
}
