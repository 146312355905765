import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import AuthStack from "../src/routes/auth";
import MainStack from "../src/routes/main";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const isLoggedIn = useSelector((state) => state?.authReducer?.isLoggedIn);

  return (
    <>
      <ToastContainer />
      <Router>{isLoggedIn ? <MainStack /> : <AuthStack />}</Router>
    </>
  );
}

export default App;
