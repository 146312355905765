export const GET_BOOKS = "GET_BOOKS";

export const getBooks = () => {
    try {
      return async dispatch => {
        console.log('in_book_action')
        const response = [{id: 1, name:"B1"},{id: 2, "name": "B2"}]
        if (response) {
          console.log('data_is_here');
          dispatch({
            type: GET_BOOKS,
            payload: response
          });
        } else {
          console.log('Unable to fetch data from the API BASE URL!');
        }
      };
    } catch (error) {
      // Add custom logic to handle errors
      console.log(error);
    }
  };