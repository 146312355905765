import axios from "axios";

// const token = localStorage.getItem("token");

export const APP_URL = "https://s2.ruptokcap.in/";

export const AuthInstance = axios.create({
  baseURL: APP_URL,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    "Content-Type": "application/json",
    timeout: 1000,
  },
  // .. other options
});

AuthInstance.interceptors.request.use(
  async (config) => {
    const token = await localStorage.getItem("token");
    config.headers.Authorization = "Bearer " + token;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

AuthInstance.interceptors.response.use(
  (response) => {
    // Any status code from range of 2xx
    // Do something with response data
    return response;
  },
  (error) => {
    // Any status codes outside range of 2xx
    // Do something with response error
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      console.log("error_message_", error.response);
      if (error.response.data.message === "Some issue occured during OTP") {
        alert("Contact Tech Team for OTP");
      } else {
        alert(error.response.data.message);
      }
    } else {
      console.log("errrrrr", error);
      alert("Alert", "Some error occured");
    }
    return null;
  }
);

export const UserInstance = axios.create({
  baseURL: APP_URL,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    timeout: 1000,
  },
  // .. other options
});
