import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../screens/main/home/Home";
import Customers from "../screens/main/customers/Customers";
import RecentTransactions from "../screens/main/home/RecentTransactions";
import Pending from "../screens/main/pending/Pending";
import Snooze from "../screens/main/snooze/Snooze";
import Users from "../screens/main/users/Users";
import LeadDetails from "../screens/main/pending/LeadDetails";
import Report from "../screens/main/report/Report";
import AllLoans from "../screens/main/customers/AllLoans";
import Reports from "../screens/main/report/Reports";
import BucketReport from "../screens/main/report/BucketReport";

function MainStack() {
  const { user } = useSelector((state) => state.authReducer);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/customers" element={<Customers />} />
      <Route path="/transactions" element={<RecentTransactions />} />
      <Route path="/leaddetails/:locid/:lmsid" element={<LeadDetails />} />
      <Route path="/allloans/:id" element={<AllLoans />} />
      <Route path="/pending" element={<Pending />} />
      <Route path="/snooze" element={<Snooze />} />
      <Route path="/report" element={<Report />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/bt-report" element={<BucketReport />} />
      {user?.is_admin ? <Route path="/users" element={<Users />} /> : null}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default MainStack;
