import React from "react";
import Moment from "moment";
import { useSelector } from "react-redux";

const Transactions = () => {
  const { locTransactions } = useSelector((state) => state.locAccountReducer);
  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mx-auto mt-10">
      <div>
        {/* Table */}
        <div
          className="overflow-x-auto"
          style={{
            width: "70vw",
          }}
        >
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-bold uppercase text-black-800 bg-slate-50 border-t border-b border-slate-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left hover:bg-slate-50">
                    Txn ID
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left hover:bg-slate-50">
                    LMS Txn ID
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left">Amount</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left">Loc Acc ID</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left">Date</div>
                </th>
                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left">Receipt No.</div>
                </th> */}
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left">Balance</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left">Txn Type</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-bold text-left">Interest rate</div>
                </th>
              </tr>
            </thead>

            <tbody className="text-sm divide-y divide-slate-200">
              {locTransactions?.map((i) => {
                return (
                  <tr className="hover:bg-slate-100" key={i.transaction_id}>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-left text-sky-500">
                        {i.transaction_id}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-left text-sky-500">
                        {i.lmsTransactionId}
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-slate-500">
                          {"₹ " +
                            parseFloat(i?.amount).toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                            })}{" "}
                        </div>
                      </div>
                    </td>

                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="font-medium text-slate-500">
                          {i?.locAccountId}
                        </div>
                      </div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-left">
                        {Moment(i.transactionDate).format("LL")}
                      </div>
                    </td>
                    {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-left">{i?.receiptNumber}</div>
                    </td> */}
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-left">{i?.runningBalance}</div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-left">{i?.transactionType}</div>
                    </td>
                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                      <div className="text-center">{i?.interestRate}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {locTransactions?.length === 0 ? (
            <header className="px-5 py-4">
              <h2 className="font-semibold text-center text-slate-800">
                No Transactions found
              </h2>
            </header>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
