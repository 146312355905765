import React, { useEffect, useState } from "react";
import phoneIcon from "../../assets/img/phone.png";
import Loader from "../../assets/lottie/Loader.jsx";
import OTP from "./OTP";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/img/logo.png";
import { sendOtpAction } from "../../redux/actions/AuthAction";

const Signin = () => {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const hitSmsAPI = (data) => dispatch(sendOtpAction(data));

  const { otpModal } = useSelector((state) => state.authReducer);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = {
      phone: phone,
    };
    hitSmsAPI(formData);
  };
  useEffect(() => {
    if (otpModal) {
      setLoading(false);
    }
  }, [otpModal]);

  return (
    <div
      className="flex items-center justify-center p-1 md:p-20"
      style={{
        height: "80vh",
        flexDirection: window.innerWidth > 1000 ? "row" : "column",
      }}
    >
      <div className="flex flex-1 items-center justify-center flex-col">
        <img alt="" src={logo} style={{ height: "40%", width: "40%" }} />
        <div className="mt-10 text-4xl font-bold text-center">
          Lender WebApp
        </div>
      </div>
      <div className="flex-1 flex justify-center items-center w-full">
        <div
          className="rounded overflow-hidden m-auto"
          style={{
            width: window.innerWidth > 1000 ? 500 : "90%",
            backgroundColor: "white",
            padding: window.innerWidth > 1000 ? 60 : 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            border: "1px solid rgba(170, 170, 170, 0.3)",
            transition: "0.2s ease-out",
          }}
        >
          {loading ? (
            <Loader size={200} />
          ) : otpModal ? (
            <OTP phone={phone} />
          ) : (
            <>
              <div className="px-6 pb-12 text-center">
                <div className="font-bold text-xl mb-2">Sign in</div>
                <p className="text-gray-700 text-base">
                  To Access your Account
                </p>
              </div>
              <div style={{ marginBottom: "20px" }} className="auth-input">
                <input
                  type="text"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value.replace(/[^0-9]/g, ""));
                  }}
                  placeholder="Enter your Phone"
                  style={{ outline: "none", fontSize: 15, width: "100%" }}
                />
                <img src={phoneIcon} alt="phone" />
              </div>
              <button
                onClick={handleSignIn}
                className="next-auth-button"
                disabled={phone?.length === 10 ? false : true}
              >
                Next
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signin;
