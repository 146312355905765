import React from "react";
import Moment from "moment";
import { useSelector } from "react-redux";

const ActivityLogs = () => {
  const { locActivityDetails } = useSelector(
    (state) => state.locAccountReducer
  );
  return (
    <div className="w-full flex flex-wrap justify-between">
      {locActivityDetails?.length === 0 ? (
        <div className="text-gray-500 text-xl font-extrabold text-center mt-10 w-full flex justify-center">
          No Activities found
        </div>
      ) : (
        locActivityDetails?.map((i) => (
          <div className="flex items-center flex-col mt-6 white-card-double">
            <div className="flex items-center justify-between w-full">
              <div className="w-1/2 text-gray-400 font-medium flex items-center text-xs font-medium">
                {i?.text}
              </div>
              <div>
                <div className=" text-right text-black text-sm font-medium">
                  {"Agent: " + i?.agent_name}
                </div>
                <div className=" text-right text-gray-500 text-xs text-medium">
                  {Moment(i.transactionDate).format("LL")}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ActivityLogs;
