import React, { useEffect, useState } from "react";
import {
  downloadAnyReport,
  getgeneratedReports,
} from "../../../redux/actions/AdminAction";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import PaginationClassic from "../../../components/PaginationClassic";
import Loader from "../../../assets/lottie/Loader.jsx";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

const Reports = () => {
  const [data, setData] = useState(null);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState(1);
  useEffect(() => {
    getgeneratedReports(page).then((res) => {
      setData(res.data.data);
      setPageData(res.data);
    });
  }, [page]);

  var today = new Date();
  const [fromDate, setFromDate] = useState("2023-01-01");
  const [toDate, setToDate] = useState(
    `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
  );
  const [filter, setFilter] = useState(100);

  const [selectedType, setSelectedType] = useState("razorpay_incoming");

  const downloadReport = () => {
    const data = {
      report: selectedType,
      startDate: fromDate,
      endDate: toDate,
    };
    downloadAnyReport(data).then((res) => {
      if (res.data.status === "success") {
        toast(`${res.data.data}, check reports section to download`, {
          progressStyle: { background: "#eba300" },
        });
        getgeneratedReports(page).then((res) => {
          setData(res.data.data);
          setPageData(res.data);
        });
      }
    });
  };

  const _BucketTypes = [
    { id: 100, value: "Custom" },
    { id: 0, value: "Today" },
    { id: 1, value: "Yesterday" },
    { id: 3, value: "Past 3 Days" },
    { id: 7, value: "Past 7 Days" },
    { id: 10, value: "Past 10 Days" },
    { id: 31, value: "Past Month" },
  ];

  const type = [
    { id: "razorpay_incoming", value: "Razorpay incoming" },
    { id: "transaction_charges", value: "Transaction charges" },
    { id: "payment_settlement", value: "Payment settlement" },
    { id: "payment_to_lender", value: "Payment to lender" },
    { id: "incoming_report", value: "Incoming report" },
  ];

  return (
    <div className="flex">
      <Sidebar />
      <div>
        <Header>
          <div className="flex flex-col w-full h-full py-4 items-center justify-center">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-xl text-slate-800">
                Generated Reports{" "}
                <span className="text-slate-500 text-lg font-medium">
                  ({pageData?.total_records})
                </span>
              </h2>
              <div className="flex justify-end items-center"></div>
            </div>
          </div>
        </Header>

        <div className="m-5 mt-2">
          <div className="white-card flex">
            <FormControl style={{ width: "200px" }}>
              <InputLabel>Select Type</InputLabel>
              <Select
                label="Select Type"
                value={selectedType}
                onChange={(e) => {
                  setSelectedType(e.target.value);
                }}
              >
                {type.map((i) => (
                  <MenuItem value={i.id}>{i.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ width: "170px", marginLeft: "20px" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="From (YYYY-MM-DD)"
                  inputFormat="YYYY-MM-DD"
                  value={fromDate}
                  onChange={(e) => {
                    var today = new Date(e);
                    setFromDate(
                      `${today.getFullYear()}-${
                        today.getMonth() + 1
                      }-${today.getDate()}`
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: "20px", width: "170px" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="To (YYYY-MM-DD)"
                  inputFormat="YYYY-MM-DD"
                  value={toDate}
                  onChange={(e) => {
                    var today = new Date(e);
                    setToDate(
                      `${today.getFullYear()}-${
                        today.getMonth() + 1
                      }-${today.getDate()}`
                    );
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <FormControl style={{ width: "150px", marginLeft: "20px" }}>
              <InputLabel>Select Duration</InputLabel>
              <Select
                label="Select Duration"
                value={filter}
                onChange={(e) => {
                  var yesterday = new Date();
                  var today = new Date();
                  yesterday.setDate(yesterday.getDate() - e.target.value);
                  setFromDate(
                    `${yesterday.getFullYear()}-${
                      yesterday.getMonth() + 1
                    }-${yesterday.getDate()}`
                  );
                  if (e.target.value === 100) {
                    setFromDate("01/01/2023");
                    setToDate(
                      `${today.getFullYear()}-${
                        today.getMonth() + 1
                      }-${today.getDate()}`
                    );
                  } else if (e.target.value === 1) {
                    setToDate(
                      `${yesterday.getFullYear()}-${
                        yesterday.getMonth() + 1
                      }-${yesterday.getDate()}`
                    );
                  } else {
                    setToDate(
                      `${today.getFullYear()}-${
                        today.getMonth() + 1
                      }-${today.getDate()}`
                    );
                  }

                  setFilter(e.target.value);
                }}
              >
                {_BucketTypes.map((i) => (
                  <MenuItem value={i.id}>{i.value}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div
              className="btn bg-indigo-500 text-white h-14 ml-6"
              onClick={downloadReport}
            >
              Generate CSV
            </div>
          </div>
          <div className="flex flex-col white-card p-8 mt-2">
            <div className="flex flex flex-wrap w-full justify-between">
              <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <main>
                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
                    <div>
                      {/* Table */}
                      <div
                        className="overflow-x-auto"
                        style={{
                          width: window.innerWidth > 1400 ? "80vw" : "70vw",
                          height: "60vh",
                        }}
                      >
                        {data === null ? (
                          <Loader size={200} />
                        ) : (
                          <table className="table-auto w-full">
                            {/* Table header */}
                            <thead className="text-xs font-bold uppercase text-black-800 bg-slate-50 border-t border-b border-slate-200">
                              <tr>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left hover:bg-slate-50">
                                    ID
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Generated By
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Report Type
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Generated On
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Start Date
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    End Date
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Status
                                  </div>
                                </th>
                              </tr>
                            </thead>

                            <tbody className="text-sm divide-y divide-slate-200">
                              {data?.map((item) => {
                                return (
                                  <tr
                                    className="hover:bg-slate-100"
                                    key={item.id}
                                  >
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left text-sky-500">
                                        {item.id}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {item?.generated_by}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {item?.report_type}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {item?.generated_on}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {item?.start_date}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {item?.end_date}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-green-500">
                                        {item?.status}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div
                                        className="btn bg-indigo-500 text-white"
                                        onClick={() => {
                                          window.open(item.url, "_target");
                                        }}
                                      >
                                        Download
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        {data?.length === 0 ? (
                          <header className="px-5 py-4">
                            <h2 className="font-semibold text-center text-slate-800">
                              No Matches found
                            </h2>
                          </header>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div className="m-3">
            <PaginationClassic
              page={parseInt(page)}
              setPage={setPage}
              totalPages={pageData?.total_pages}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
