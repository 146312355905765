import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_LOGIN,
  SET_OTP_MODAL,
  HIDE_OTP_MODAL,
  STOP_LOADER,
  LOADER_STATE,
} from "../actions/AuthAction";

const token = localStorage.getItem("token");
const parseUser = () => {
  let data = localStorage.getItem("user");
  try {
    const res = JSON.parse(data);
    return res;
  } catch (error) {
    return null;
  }
};

const initialState = {
  token: token ? token : null,
  user: parseUser(),
  isLoggedIn: token ? true : false,
  otpModal: false,
  loading: false,
};

function authReducer(state = initialState, action) {
  console.log("auth_reducer");
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.data,
        isLoggedIn: true,
        otpModal: false,
        loading: false,
      };
    case LOGOUT_USER:
      return {
        ...state,
        token: null,
        user: null,
        isLoggedIn: false,
        loading: false,
      };
    case SET_LOGIN:
      console.log("set_user_login_reducer");
      return { ...state, isLoggedIn: true, user: action.payload };
    case SET_OTP_MODAL:
      console.log("set_user_login_reducer");
      return { ...state, otpModal: true, loading: false };
    case HIDE_OTP_MODAL:
      console.log("set_user_login_reducer");
      return { ...state, otpModal: false };
    case STOP_LOADER:
      console.log("set_user_login_reducer");
      return { ...state, loading: false };
    case LOADER_STATE:
      console.log("set_user_login_reducer");
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

export default authReducer;
