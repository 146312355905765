import { AuthInstance } from "../api/ApiRepository";
export const LOGIN_USER = "LOGIN_USER";
export const SET_LOGIN = "SET_LOGIN";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_OTP_MODAL = "SET_OTP_MODAL";
export const HIDE_OTP_MODAL = "HIDE_OTP_MODAL";
export const STOP_LOADER = "STOP_LOADER";
export const LOADER_STATE = "LOADER_STATE";

export const loginUserAPI = (data) => {
  try {
    return async (dispatch) => {
      console.log("in_book_action");
      const res = await AuthInstance.post("auth/verifyingOTP", data);
      if (res) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.data));
        dispatch({
          type: LOGIN_USER,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
        dispatch({
          type: STOP_LOADER,
        });
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const setLoaderState = (state) => {
  try {
    return async (dispatch) => {
      dispatch({
        type: LOADER_STATE,
        payload: state,
      });
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const sendOtpAction = (data) => {
  try {
    return async (dispatch) => {
      await AuthInstance.post("auth/login", data).then((res) => {
        dispatch({
          type: SET_OTP_MODAL,
        });
      });
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const setOtpModal = (data) => {
  try {
    return async (dispatch) => {
      console.log("in_book_action");
      const data = [];
      dispatch({
        type: HIDE_OTP_MODAL,
        payload: data,
      });
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const setUserLogin = () => {
  console.log("Set_user_logoinisn");
  try {
    return async (dispatch) => {
      const d = await localStorage.getItem("user");
      const data = JSON.parse(d);
      console.log("set_user_login", data);
      dispatch({
        type: SET_LOGIN,
        payload: data,
      });
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const forgotPassword = async (data) => {
  try {
    console.log("in_book_action");
    const res = await AuthInstance.post("auth/forgotPassword", data);
    return res.data;
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const resetPassword = async (data) => {
  try {
    console.log("in_reset_action");
    const res = await AuthInstance.post("auth/resetPassword", data);
    return res.data;
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const changePassword = async (data) => {
  try {
    console.log("in_change_action");
    const res = await AuthInstance.post("auth/changePassword", data);
    if (res) {
      return true;
    } else {
      console.log("logs----->", res);
      return false;
    }
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const logOutApi = () => {
  localStorage.clear();
  return async (dispatch) => {
    console.log("in_logout_function");
    const res = { token: null };
    await localStorage.getAllKeys().then(localStorage.multiRemove);
    console.log("data_is_here");
    dispatch({
      type: LOGOUT_USER,
      payload: res,
    });
  };
};

const ref = () => {
  console.log("Login_User");
  try {
    return async (dispatch) => {
      // const response = await axios.get(`${BASE_URL}`);
      const response = { data: "" };
      if (response.data) {
        dispatch({
          type: LOGIN_USER,
          payload: response.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    // Add custom logic to handle errors
    console.log(error);
  }
};
