import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsersAPI } from "../../../redux/actions/AdminAction";
import Loader from "../../../assets/lottie/Loader.jsx";
import UpdateUserModal from "../../../components/UpdateUserModal";
import { AiOutlineUserAdd } from "react-icons/ai";
import AddUserModal from "../../../components/AddUserModal";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { getRoles, getDepartments } from "../../../redux/actions/UserAction";

const Users = () => {
  const dispatch = useDispatch();
  const getUsers = () => dispatch(getAllUsersAPI());
  const { allUsers } = useSelector((state) => state.adminReducer);
  const [showModal, setShowModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const [roles, setRoles] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    getUsers();
    getRoles().then((res) => {
      setRoles(res.data);
    });
    getDepartments().then((res) => {
      setDepartments(res.data);
    });
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full">
        <Header>
          <div className="flex w-full items-center justify-between">
            <div className="text-xl font-bold font-black mb-4">Users</div>
            <div
              className="text-xl font-bold text-yellow-500 flex items-center cursor-default"
              onClick={() => {
                setAddModal(true);
              }}
            >
              <AiOutlineUserAdd className="mr-2" size={30} />
              Add User
            </div>
          </div>
        </Header>
        <div className="m-5">
          <div className="flex flex-col flex-1 white-card-inner">
            <div className="flex flex flex-wrap w-full justify-between">
              {allUsers === null ? (
                <Loader size={200} />
              ) : (
                allUsers?.map((i) => (
                  <div
                    onClick={() => {
                      setShowModal(true);
                      localStorage.setItem("updateUser", JSON.stringify(i));
                    }}
                    className="flex items-center flex-col mt-6 white-card-double"
                  >
                    <div className="flex items-center justify-between w-full">
                      <div className="w-1/2 text-black font-bold flex items-center text-base font-medium">
                        {i?.first_name} {i?.last_name}
                      </div>
                      <div className="w-1/2 text-right text-black text-lg font-bold">
                        {i?.phone}
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className="w-1/2 text-gray-500 text-xs text-medium">
                        {i?.email}
                      </div>
                      <div className="w-1/2 text-right text-gray-500 text-xs text-medium">
                        {i?.is_admin ? "Admin" : "User"}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <UpdateUserModal
            showModal={showModal}
            setShowModal={setShowModal}
            roles={roles}
            departments={departments}
          />
          <AddUserModal
            showModal={addModal}
            setShowModal={setAddModal}
            roles={roles}
            departments={departments}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
