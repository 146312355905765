/* eslint-disable no-unused-expressions */
import React from "react";

function PaginationClassic({ page, setPage, totalPages }) {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
      <nav
        className="mb-4 sm:mb-0 sm:order-1"
        role="navigation"
        aria-label="Navigation"
      >
        <ul className="flex justify-center">
          <li className="ml-3 first:ml-0">
            <a
              className={
                page <= 1
                  ? "btn bg-white border-slate-200 text-slate-300 cursor-not-allowed"
                  : "btn bg-white border-slate-200 hover:border-slate-300 text-indigo-500"
              }
              href="#0"
              onClick={() => {
                page <= 1 ? null : setPage(page - 1);
              }}
            >
              &lt;- Previous
            </a>
          </li>
          <li className="ml-3 first:ml-0">
            <a
              className={
                page >= totalPages
                  ? "btn bg-white border-slate-200 text-slate-300 cursor-not-allowed"
                  : "btn bg-white border-slate-200 hover:border-slate-300 text-indigo-500"
              }
              href="#0"
              onClick={() => {
                page >= totalPages ? null : setPage(page + 1);
              }}
            >
              Next -&gt;
            </a>
          </li>
        </ul>
      </nav>
      <div className="text-sm text-slate-500 text-center sm:text-left">
        Showing <span className="font-medium text-slate-600">{page}</span> of{" "}
        <span className="font-medium text-slate-600">{totalPages}</span> pages
      </div>
    </div>
  );
}

export default PaginationClassic;
