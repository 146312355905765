import {
  GET_RECENT_TRANSACTIONS,
  GET_ALL_TRANSACTIONS,
  TODAY_STATS,
  BUCKET_STATS,
} from "../actions/TransactionsAction";

const initialState = {
  recentTransactions: null,
  allTransactions: [],
  todayStats: null,
  bucketStats: null,
};

function transactionReducer(state = initialState, action) {
  console.log("admin_reducer");
  switch (action.type) {
    case GET_RECENT_TRANSACTIONS:
      console.log("In_recent_transaction_case", action.payload.data);
      return { ...state, recentTransactions: action.payload.data };
    case GET_ALL_TRANSACTIONS:
      console.log("In_all_transactions_case_offset", action.payload.offset);
      return { ...state, allTransactions: action.payload.data };
    case TODAY_STATS:
      console.log("today_stats", action.payload.data);
      return { ...state, todayStats: action.payload.data };
    case BUCKET_STATS:
      console.log("today_stats", action.payload.data);
      return { ...state, bucketStats: action.payload.data };
    default:
      return state;
  }
}

export default transactionReducer;
