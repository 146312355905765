import React, { useEffect, useState } from "react";
import {
  getBtReportData,
  getBtReportLink,
} from "../../../redux/actions/AdminAction";
import PaginationClassic from "../../../components/PaginationClassic";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import Moment from "moment";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { toast } from "react-toastify";
import Loader from "../../../assets/lottie/Loader.jsx";

const BucketReport = () => {
  const [status, setstatus] = useState("ALL");
  const [data, setData] = useState(null);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  useEffect(() => {
    getBtReportData({ bucketing: status }).then((res) => {
      setData(res.data.data.bucketData);
      setPage(parseInt(res.data.data.page_no));
      setTotalPage(res.data.data.total_page);
    });
  }, [status]);

  const downloadReport = () => {
    getBtReportLink({ bucketing: status }).then((res) => {
      console.log(res);
      if (res.data.status === 200) {
        toast(`${res.data.message}, check reports section to download`, {
          progressStyle: { background: "#eba300" },
        });
      }
    });
  };

  const _BucketTypes = [
    { id: "ALL", value: "All" },
    { id: "btx", value: "Bt x" },
    { id: "bt1", value: "Bt 1" },
    { id: "bt2", value: "Bt 2" },
    { id: "bt3", value: "Bt 3" },
    { id: "NPA", value: "NPA" },
  ];

  return (
    <div className="flex">
      <Sidebar />
      <div>
        <Header height="90px">
          <div className="flex flex-col w-full h-full py-4 items-center justify-between">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-xl text-slate-800">
                Bucket report
              </h2>
              <div className="flex justify-end items-center">
                <FormControl style={{ width: "150px", marginLeft: "10px" }}>
                  <InputLabel>Select Bucket</InputLabel>
                  <Select
                    value={status}
                    label="Select Bucket"
                    onChange={(e) => {
                      setstatus(e.target.value);
                    }}
                  >
                    {_BucketTypes?.map((i) => (
                      <MenuItem value={i.id}>{i.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div
                  className="btn bg-indigo-500 text-white h-14 ml-10 cursor-pointer"
                  onClick={() => {
                    downloadReport();
                  }}
                >
                  Generate CSV
                </div>
              </div>
            </div>
          </div>
        </Header>

        <div className="m-5 mt-2">
          <div className="flex flex-col white-card p-8">
            <div className="flex flex flex-wrap w-full justify-between">
              <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <main>
                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
                    <div>
                      {/* Table */}
                      <div
                        className="overflow-x-auto"
                        style={{
                          width: window.innerWidth > 1400 ? "80vw" : "70vw",
                        }}
                      >
                        {data === null ? (
                          <Loader size={200} />
                        ) : (
                          <table className="table-auto w-full">
                            {/* Table header */}
                            <thead className="text-xs font-bold uppercase text-black-800 bg-slate-50 border-t border-b border-slate-200">
                              <tr>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left hover:bg-slate-50">
                                    LOC ID
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Loc Acc ID
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Folder ID
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Lms client id
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Customer Name
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Bank Lan
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Scheme Name
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Total Outstanding
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Total Withdrawal
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    No. of DPD
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Status
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Sub Status
                                  </div>
                                </th>
                              </tr>
                            </thead>

                            <tbody className="text-sm divide-y divide-slate-200">
                              {data?.map((customer) => {
                                return (
                                  <tr
                                    className="hover:bg-slate-100"
                                    key={customer.loc_id}
                                  >
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left text-sky-500">
                                        {customer.loc_id}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.loc_account_id}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.folder_id}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.lms_client_id}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.customer_name}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.bank_lan}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.scheme_name}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {"₹ " +
                                          parseFloat(
                                            customer?.total_outstanding
                                          ).toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                          })}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {"₹ " +
                                          parseFloat(
                                            customer?.total_withdrawal
                                          ).toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                          })}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.no_of_dpd}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.status}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.sub_status}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        {data?.length === 0 ? (
                          <header className="px-5 py-4">
                            <h2 className="font-semibold text-center text-slate-800">
                              No Matches found
                            </h2>
                          </header>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div className="m-2">
            <PaginationClassic
              page={parseInt(page)}
              setPage={setPage}
              totalPages={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BucketReport;
