import { AuthInstance } from "../api/ApiRepository";
export const GET_BUCKET_X_DATA = "GET_BUCKET_X_DATA";
export const SET_LOC_ACCOUNTS_DETAILS = "SET_LOC_ACCOUNTS_DETAILS";
export const SET_CUSTOMER_LOC_ACCOUNTS = "SET_CUSTOMER_LOC_ACCOUNTS";

export const GetBucketDataAPI = async (data) => {
  try {
    console.log("in_book_action");
    const res = await AuthInstance.post(
      "api/app/fetch-pending-dues-bucket",
      data
    );
    if (res) {
      console.log("btxx_data_is_here_", res.data.data);
      return res.data.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return [];
    }
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const GetSnoozeDataAPI = async (data) => {
  try {
    console.log("in_snooze_action");
    const res = await AuthInstance.post(
      "api/app/fetch-snoozed-dues-bucket",
      data
    );
    if (res) {
      console.log("btxx_data_is_here_", res.data.data);
      return res.data.data;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return [];
    }
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const RazorpayPaymentLink = async (data) => {
  try {
    console.log("in_razorpay_action_", data);
    const res = await AuthInstance.post("api/send-payment-link", data);
    if (res) {
      console.log("razorpay_data_is_here_", res.data);
      return true;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return false;
    }
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const CallDespostionAction = async (data) => {
  try {
    console.log("in_razorpay_action");
    const res = await AuthInstance.post("api/app/call-disposition", data);
    if (res) {
      console.log("call_desp_data_is_here_", res.data);
      return true;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return false;
    }
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const KnowlarityCallAPI = async (data) => {
  try {
    console.log("in_razorpay_action");
    const res = await AuthInstance.post("api/make-call", data);
    if (res) {
      console.log("knowlarity_data_is_here_", res.data);
      return true;
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
      return false;
    }
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const GetLocAccountData = (data) => {
  return async (dispatch) => {
    console.log("in_razorpay_action");
    const res = await AuthInstance.post("api/app/get-loc-account-logs", data);
    if (res) {
      dispatch({
        type: SET_LOC_ACCOUNTS_DETAILS,
        payload: res.data,
      });
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
    }
  };
};
export const SyncData = async (id) => {
  const res = await AuthInstance.get(
    `api/lms/refresh-specific-customer?clientId=${id}`
  );
  if (res) {
    console.log("sync_data_is_here_", res.data);
    alert(res.data.message);
  }
};

export const GetCustomerLocAccounts = (data) => {
  return async (dispatch) => {
    console.log("in_razorpay_action");
    const res = await AuthInstance.post(
      "api/app/get-user-all-loc-accounts",
      data
    );
    if (res) {
      console.log("loc_data_is_here_", res.data);
      dispatch({
        type: SET_CUSTOMER_LOC_ACCOUNTS,
        payload: res.data,
      });
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
    }
  };
};
