import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecentTransactions } from "../../../redux/actions/TransactionsAction";
import Moment from "moment";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import Loader from "../../../assets/lottie/Loader.jsx";

const RecentTransactions = () => {
  const dispatch = useDispatch();
  const RecentTransactions = (data) => dispatch(getRecentTransactions(data));
  const { recentTransactions } = useSelector(
    (state) => state.transactionReducer
  );
  useEffect(() => {
    RecentTransactions({});
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full">
        <Header>
          <div className="text-xl text-center w-full font-bold font-black mb-4">
            Recent Transactions
          </div>
        </Header>
        <div className="m-5">
          <div className="flex flex-col flex-1 white-card-inner">
            <div className="flex flex-wrap w-full justify-between">
              {recentTransactions === null ? (
                <Loader size={200} />
              ) : (
                recentTransactions.map((i) => (
                  <div className="flex items-center flex-col mt-6 white-card-double">
                    <div className="flex items-center justify-between w-full">
                      <div className="w-1/2 text-black font-bold flex items-center text-base font-medium">
                        {i?.displayName}
                      </div>
                      <div className="w-1/2 text-right text-black text-lg font-bold">
                        {"₹ " +
                          parseFloat(i?.amount).toLocaleString("en-IN", {
                            maximumFractionDigits: 2,
                          })}
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <div className="w-1/2 text-gray-500 text-xs text-medium">
                        LOC ID: {i?.locAccountId}
                      </div>
                      <div className="w-1/2 text-right text-gray-500 text-xs text-medium">
                        {Moment(i.transactionDate).format("LL")}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentTransactions;
