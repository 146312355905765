import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import booksReducer from "./reducers/Books";
import locAccountReducer from "./reducers/LocAccountReducer";
import authReducer from "./reducers/AuthReducer";
import adminReducer from "./reducers/AdminReducer";
import transactionReducer from "./reducers/TransactionReducer";
import UserReducer from "./reducers/UserReducer";

const rootReducer = combineReducers({
  booksReducer: booksReducer,
  authReducer: authReducer,
  adminReducer: adminReducer,
  transactionReducer: transactionReducer,
  UserReducer: UserReducer,
  locAccountReducer: locAccountReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
