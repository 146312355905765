import React, { useEffect, useState } from "react";
import {
  GetCustomerLocAccounts,
  GetLocAccountData,
  SyncData,
} from "../../../redux/actions/BucketAction";
import Moment from "moment";
import Transactions from "../../../components/leadDetails/Transactions";
import ActivityLogs from "../../../components/leadDetails/ActivityLogs";
import LocAccounts from "../../../components/leadDetails/LocAccounts";
import { useDispatch } from "react-redux";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { useParams } from "react-router-dom";
import Loader from "../../../assets/lottie/Loader.jsx";
import { getLoanDetails } from "../../../redux/actions/AdminAction";
import CopyToClipboard from "react-copy-to-clipboard";
import { VscCopy } from "react-icons/vsc";
import { IoMdArrowRoundBack } from "react-icons/io";
import { toast } from "react-toastify";

const LeadDetails = () => {
  const dispatch = useDispatch();

  const { locid, lmsid } = useParams();
  const [data, setData] = useState(null);
  const [mainData, setMainData] = useState(null);
  const [subData, setSubData] = useState({});
  const [option, setOption] = useState(1);
  useEffect(() => {
    getLoanDetails(locid).then((res) => {
      setData(res.loanDetails);
      setMainData(res.masterAccountDetails);
      setSubData(res.subAccountDetails);
      setSubData(res.subAccountDetails);
    });
    const data = {
      locID: locid,
      lmsClientId: lmsid,
    };
    dispatch(GetCustomerLocAccounts(data));
    dispatch(GetLocAccountData(data));
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full">
        <Header>
          <IoMdArrowRoundBack size={30} onClick={() => window.history.back()} />
          <div className="text-xl text-center w-full font-bold font-black mb-4">
            Loan details (Loc ID: {locid})
          </div>
          <div
            className="btn bg-indigo-500 text-white w-24"
            onClick={() => {
              window.confirm("Do you want to sync data ?")
                ? SyncData(lmsid)
                : console.log("no");
            }}
          >
            Sync
          </div>
        </Header>
        {data === null ? (
          <div className="h-1/2 w-full flex items-center justify-center">
            <Loader size={200} />
          </div>
        ) : (
          <div className="m-5">
            <div className="flex flex-col flex-1 white-card-inner">
              <div className="flex items-center w-full mt-3 white-card">
                <div className="w-full">
                  <div
                    className="flex items-center justify-between w-full pb-4"
                    style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                  >
                    <div>
                      <div className="text-yellow-500 font-bold flex items-center text-lg">
                        {data?.name}
                      </div>
                      <div className="text-gray-400 font-medium flex items-center text-sm">
                        {data?.phone}
                      </div>
                      <div className="text-gray-400 font-medium flex items-center text-sm">
                        {data?.city}
                      </div>
                      <div className="text-gray-400 font-medium flex items-center text-sm">
                        Folder ID: {mainData?.folderId}
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-yellow-500 font-medium flex items-center text-sm text-medium ml-auto">
                        {data?.dispStatus ? (
                          <div className="text-green-500 font-medium text-xl">
                            {data?.dispStatus}
                          </div>
                        ) : (
                          "No Status"
                        )}
                      </div>
                      <div className="text-gray-500 text-xs text-medium ml-auto">
                        Status
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full mt-6">
                    <div className="flex flex-col w-1/4">
                      <div className="text-black font-bold flex items-center text-sm font-medium">
                        {"₹ " +
                          parseFloat(data?.foreclosureAmount).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Total Outstanding
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-1/4">
                      <div className="text-black font-bold flex items-center text-sm font-medium ">
                        {Moment(data?.activatedOnDate).format("LL")}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Loan Start Date
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-1/4">
                      <div className="text-black font-bold flex items-center text-sm font-medium">
                        {Moment(data?.nextRepaymentDate).format("LL")}
                      </div>
                      <div className="text-gray-500 text-xs text-medium ">
                        Next Repayment Date
                      </div>
                    </div>
                    <div className="flex flex-col w-1/4">
                      <div className="text-black font-bold flex items-center text-sm font-medium ml-auto">
                        {Moment(data?.lastActiveTransactionDate).format("LL")}
                      </div>
                      <div className="text-gray-500 text-xs text-medium ml-auto">
                        Last Payment Date
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex items-center justify-between w-full mt-6 pb-4"
                    style={{ borderBottom: "0.5px dotted gray" }}
                  >
                    <div className="flex flex-col w-1/4">
                      <div className="text-black font-bold flex items-center text-sm font-medium">
                        {"₹ " +
                          parseFloat(
                            data?.lastTransactionAmount
                          ).toLocaleString("en-IN", {
                            maximumFractionDigits: 2,
                          })}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Last Payment Amount
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-1/4">
                      <div className="text-black font-bold flex items-center text-sm font-medium">
                        {"₹ " +
                          parseFloat(data?.nextRepaymentAmount).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Next RePayment Amount
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-1/4">
                      <div className="text-black font-bold flex items-center text-sm font-medium ">
                        {"₹ " +
                          parseFloat(data?.principalAmount).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Loan Amount
                      </div>
                    </div>
                    <div className="flex flex-col w-1/4">
                      <div className="text-black font-bold flex items-center text-sm font-medium ml-auto">
                        {data?.agent_firstname
                          ? data?.agent_firstname
                          : "No one"}
                      </div>
                      <div className="text-gray-500 text-xs text-medium ml-auto">
                        Contacted By
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full pt-4">
                    <div className="flex flex-col">
                      <div className="text-yellow-500 font-extrabold flex items-center text-lg font-medium ml-auto">
                        {data?.scheme ? data?.scheme : " "}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Scheme
                      </div>
                    </div>
                    <div
                      className="flex items-center px-4 py-2"
                      style={{
                        border: "1px dotted #eba300",
                        borderRadius: "6px",
                      }}
                    >
                      <div className="">
                        <div className="mt-1 text-base font-bold font-black">
                          Acc. Holder Name
                        </div>
                        <div className="text-sm font-normal text-gray-500 mt-2">
                          Ruptok
                        </div>
                      </div>
                      <div className="ml-10">
                        <div className="mt-1 text-base font-bold font-black">
                          Acc. Number
                        </div>
                        <div className="text-sm font-normal text-gray-500 mt-2">
                          {mainData?.va_account_no}
                        </div>
                      </div>
                      <div className="ml-10">
                        <div className="mt-1 text-base font-bold font-black">
                          IFSC Code
                        </div>
                        <div className="text-sm font-normal text-gray-500 mt-2">
                          {mainData?.va_ifsc}
                        </div>
                      </div>
                      <CopyToClipboard
                        text={`Acc Holder Name :  Ruptok Capital, Account Number : ${mainData?.va_account_no}, IFSC Code : ${mainData?.va_ifsc}`}
                        onCopy={() => {
                          toast("Account details copied", {
                            progressStyle: { background: "#eba300" },
                          });
                        }}
                      >
                        <VscCopy size={25} color="#eba300" className="ml-14" />
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-between flex-wrap w-full mt-3">
                <div className="white-card-double">
                  <div
                    className="flex items-center justify-between w-full pb-4"
                    style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                  >
                    <div>
                      <div className="text-yellow-500 font-bold flex items-center text-lg">
                        Main Lending Account
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full mt-6">
                    <div className="flex flex-col w-1/3">
                      <div className="text-black font-bold flex items-center text-sm font-medium">
                        {"₹ " +
                          parseFloat(
                            mainData?.foreclosureAmount
                          ).toLocaleString("en-IN", {
                            maximumFractionDigits: 2,
                          })}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Total Outstanding
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-1/3">
                      <div className="text-black font-bold flex text-sm font-medium">
                        {"₹ " +
                          parseFloat(mainData?.totalWithdrawals).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Total Withdrawals
                      </div>
                    </div>
                    <div className="flex flex-col w-1/3">
                      <div className="text-black font-bold flex items-center text-center text-xs font-medium ml-auto">
                        {mainData?.externalId}
                      </div>
                      <div className="text-gray-500 text-xs text-medium ml-auto">
                        Bank LAN ID
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-full mt-6 pb-4">
                    <div className="flex flex-col w-1/3">
                      <div className="text-black font-bold flex items-center text-xs font-medium mr-2">
                        {mainData?.lender_mapped}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Lender Name
                      </div>
                    </div>
                    <div className="flex flex-col items-center w-1/3">
                      <div className="text-black font-bold flex items-center text-center text-xs text-black-900">
                        {mainData?.savingsProductName}
                      </div>
                      <div className="text-gray-500 text-xs text-medium">
                        Scheme Name
                      </div>
                    </div>
                    <div className="flex flex-col w-1/3">
                      <div className="text-black font-bold flex items-center text-sm font-medium ml-auto">
                        {mainData?.locAccountId}
                      </div>
                      <div className="text-gray-500 text-xs text-medium ml-auto">
                        LMS Account Id
                      </div>
                    </div>
                  </div>
                </div>
                <div className="white-card-double h-full">
                  <div
                    className="flex items-center justify-between w-full pb-4"
                    style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                  >
                    <div>
                      <div className="text-yellow-500 font-bold flex items-center text-lg">
                        Co Lending Account
                      </div>
                    </div>
                  </div>
                  {subData.locAccountId ? (
                    <div>
                      <div className="flex items-center justify-between w-full mt-6">
                        <div className="flex flex-col w-1/3">
                          <div className="text-black font-bold flex items-center text-sm font-medium">
                            {"₹ " +
                              parseFloat(
                                subData?.foreclosureAmount
                              ).toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })}
                          </div>
                          <div className="text-gray-500 text-xs text-medium">
                            Total Outstanding
                          </div>
                        </div>
                        <div className="flex flex-col items-center w-1/3">
                          <div className="text-black font-bold flex text-sm font-medium">
                            {"₹ " +
                              parseFloat(
                                subData?.totalWithdrawals
                              ).toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })}
                          </div>
                          <div className="text-gray-500 text-xs text-medium">
                            Total Withdrawals
                          </div>
                        </div>
                        <div className="flex flex-col w-1/3">
                          <div className="text-black font-bold flex items-center text-center text-xs font-medium ml-auto">
                            {subData?.externalId}
                          </div>
                          <div className="text-gray-500 text-xs text-medium ml-auto">
                            Bank LAN ID
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full mt-6 pb-4">
                        <div className="flex flex-col w-1/3">
                          <div className="text-black font-bold flex items-center text-xs mr-2 font-medium">
                            {subData?.lender_mapped}
                          </div>
                          <div className="text-gray-500 text-xs text-medium">
                            Lender Name
                          </div>
                        </div>
                        <div className="flex flex-col items-center w-1/3">
                          <div className="text-black font-bold flex items-center text-center text-xs text-black-900">
                            {subData?.savingsProductName}
                          </div>
                          <div className="text-gray-500 text-xs text-medium">
                            Scheme Name
                          </div>
                        </div>
                        <div className="flex flex-col w-1/3">
                          <div className="text-black font-bold flex items-center text-sm font-medium ml-auto">
                            {subData?.locAccountId}
                          </div>
                          <div className="text-gray-500 text-xs text-medium ml-auto">
                            LMS Account Id
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="pt-12 w-full flex items-center justify-center text-gray-500 text-lg font-extrabold">
                      Co Lending Account doesn't exists for this loan account
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center w-full mt-3 white-card">
                <div
                  className="w-full flex items-center justify-between white-card"
                  style={{ width: "70%" }}
                >
                  <div
                    className="text-black-900 text-xl font-extrabold pb-1 h-8 cursor-default"
                    style={
                      option === 1
                        ? { borderBottom: "4px solid #eba300" }
                        : null
                    }
                    onClick={() => {
                      setOption(1);
                    }}
                  >
                    Transactions
                  </div>
                  <div
                    className="text-black-900 text-xl font-extrabold pb-1 h-8 cursor-default"
                    style={
                      option === 2
                        ? { borderBottom: "4px solid #eba300" }
                        : null
                    }
                    onClick={() => {
                      setOption(2);
                    }}
                  >
                    Activity logs
                  </div>
                  <div
                    className="text-black-900 text-xl font-extrabold pb-1 h-8 cursor-default"
                    style={
                      option === 3
                        ? { borderBottom: "4px solid #eba300" }
                        : null
                    }
                    onClick={() => {
                      setOption(3);
                    }}
                  >
                    Loc Accounts
                  </div>
                </div>
                <div className="w-full flex items-center justify-between">
                  {option === 1 ? (
                    <Transactions />
                  ) : option === 2 ? (
                    <ActivityLogs />
                  ) : option === 3 ? (
                    <LocAccounts />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LeadDetails;
