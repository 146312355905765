import React, { useState } from "react";
import { CallDespostionAction } from "../redux/actions/BucketAction";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const SnoozeModal = ({ setShowModal, showModal, item }) => {
  const [mainStatusData, setMainStatus] = useState("connected");
  const [connectedStatusData, setConnectedData] = useState(null);
  const [notConnectedStatusData, setNotConnectedData] = useState(null);
  const [remarkText, setRemarkText] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [sendDate, setSendDate] = useState(true);

  const mainStatus = [
    { title: "Connected", value: "connected" },
    { title: "Not Connected", value: "not_connected" },
  ];

  const connectedStatus = [
    { title: "Call Back", value: "call_back" },
    { title: "Out of Station", value: "out_of_station" },
    { title: "Already Paid", value: "already_paid" },
    { title: "Agreed to Pay", value: "agreed_to_pay" },
    { title: "Not agreeing to pay", value: "not_agreeing_to_pay" },
  ];

  const notConnectedStatus = [
    { title: "Did not Respond", value: "did_not_respond" },
    { title: "Switched off", value: "switched_off" },
    { title: "Wrong Number", value: "wrong_number" },
    { title: "Not Reachable", value: "not_reachable" },
  ];

  const hitCallDespositionAPI = async (data) => {
    const res = await CallDespostionAction(data);
    return res;
  };

  const sendData = async (date) => {
    setShowModal(false);
    const subStatus = connectedStatusData
      ? connectedStatusData
      : notConnectedStatusData;
    const raw = {
      locId: item.locAccountId,
      status: mainStatusData,
      subStatus: subStatus,
      remark: remarkText,
      snoozeTill: sendDate ? selectedDate : null,
    };
    console.log("raw_data_call_desposition_", raw);
    hitCallDespositionAPI(raw);
  };

  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal relative bg-white rounded-lg shadow">
          <div className="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-black-900">
              Snooze User
            </h3>
          </div>

          {/* ---------- main body--------- */}
          <div style={{ width: "80%" }} className="mx-auto">
            <div className="mt-8">
              <div className="font-medium text-black-900">
                Choose, if connected or not ?
              </div>
              <div className="flex items-center justify-between mt-4">
                {mainStatus.map((i) => (
                  <div
                    onClick={() => {
                      setMainStatus(i.value);
                    }}
                    className={
                      i.value === mainStatusData
                        ? "selected-tab"
                        : "not-selected-tab"
                    }
                  >
                    {i.title}
                  </div>
                ))}
              </div>
            </div>
            <div style={{ height: "180px" }} className="mt-8">
              <div className="font-medium text-black-900">
                Choose the option
              </div>
              <div>
                {mainStatusData === "connected" ? (
                  <div
                    className="flex flex-wrap items-center justify-between"
                    style={{ height: "160px" }}
                  >
                    {connectedStatus.map((i) => (
                      <div
                        onClick={() => {
                          setConnectedData(i.value);
                        }}
                        className={
                          i.value === connectedStatusData
                            ? "selected-tab mt-3"
                            : "not-selected-tab mt-3"
                        }
                      >
                        {i.title}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="flex flex-wrap items-center justify-between"
                    style={{ height: "110px" }}
                  >
                    {notConnectedStatus.map((i) => (
                      <div
                        onClick={() => {
                          setNotConnectedData(i.value);
                        }}
                        className={
                          i.value === notConnectedStatusData
                            ? "selected-tab mt-3"
                            : "not-selected-tab mt-3"
                        }
                      >
                        {i.title}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="mt-8 flex items-center w-full">
              <div className="w-1/2">
                <div className="font-medium text-black-900">Add a Remark</div>
                <input
                  type="text"
                  className="input-remark mt-3 h-10"
                  placeholder="Remark"
                  required
                  value={remarkText}
                  onChange={(e) => setRemarkText(e.target.value)}
                />
              </div>
              <div className="w-1/2">
                <div className="font-medium text-black-900">
                  Want a reminder for this call ?
                </div>
                {datePickerVisible ? (
                  <div className="mt-6">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DesktopDatePicker
                        label="Date"
                        inputFormat="MM/DD/YYYY"
                        value={selectedDate}
                        onChange={(e) => {
                          setSelectedDate(e);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                ) : (
                  <div className="flex w-full items-center justify-between mt-3">
                    <div
                      className={
                        sendDate
                          ? "upload-button w-1/2 mr-2 h-10"
                          : "cancel-button w-1/2 ml-2 h-10"
                      }
                      onClick={() => {
                        setDatePickerVisible(true);
                      }}
                    >
                      Yes
                    </div>
                    <div
                      className={
                        sendDate
                          ? "cancel-button w-1/2 mr-2 h-10"
                          : "upload-button w-1/2 ml-2 h-10"
                      }
                      onClick={() => {
                        setShowModal(false);
                        window.location.reload();
                      }}
                    >
                      No
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="flex items-center p-6 space-x-2 mx-auto"
            style={{ width: "85%" }}
          >
            <button onClick={sendData} className="upload-button">
              Snooze
            </button>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              type="button"
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default SnoozeModal;
