import React, { useEffect, useState } from "react";
import { IoRadioButtonOn } from "react-icons/io5";
import { VscCopy } from "react-icons/vsc";
import { RazorpayPaymentLink } from "../redux/actions/BucketAction";
import Loader from "../assets/lottie/Loader.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";

const PaymentModal = ({ setShowModal, showModal, item }) => {
  const [isOtherAmount, setIsOtherAmount] = useState(false);
  const [foreclosureAmount, setForeclosureAmount] = useState(false);
  const [customAmount, setCustomAmount] = useState("");
  const [loader, setLoader] = useState(false);

  const sendLink = async () => {
    setLoader(true);
    const res = await _sendPaymentLink(
      item.locAccountId,
      isOtherAmount,
      customAmount
    );
    if (res) {
      setShowModal(false);
      setLoader(false);
      alert("succesfully sent");
    } else {
      setLoader(false);
    }
  };
  useEffect(() => {
    setIsOtherAmount(false);
    setCustomAmount("");
  }, [showModal]);

  const _sendPaymentLink = async (id, isOtherAmount, customAmount) => {
    const formdata = new FormData();
    formdata.append("locId", 11071);
    let data;
    if (foreclosureAmount) {
      data = {
        locId: id,
        paymentType: "foreclosure",
      };
    } else {
      data = {
        locId: id,
        is_custom: isOtherAmount ? 1 : 0,
        custom_amount: customAmount,
      };
    }
    const res = await RazorpayPaymentLink(data);
    return res;
  };

  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal relative bg-white rounded-lg shadow">
          <div className="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-black-900">
              Payment Options
            </h3>
          </div>
          {/* ---------- main body--------- */}
          <div className="grid gap-4 px-5 pt-5">
            <div className="flex">
              <div
                className="flex flex-1 py-4 mr-2"
                style={{ border: "1px dotted #eba300", borderRadius: "6px" }}
                onClick={() => {
                  setIsOtherAmount(false);
                  setForeclosureAmount(false);
                }}
              >
                <div
                  className="pl-5"
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!isOtherAmount && !foreclosureAmount ? (
                    <IoRadioButtonOn size={25} color="#eba300" />
                  ) : (
                    <div className="radio-icon"></div>
                  )}
                </div>
                <div className="ml-5">
                  <div className="text-base font-bold font-black">
                    Interest Amount
                  </div>
                  <div className="text-md font-medium text-black-500">
                    {"₹ " +
                      parseFloat(
                        item?.nextRepaymentAmountWithTxn
                      ).toLocaleString("en-IN", {
                        maximumFractionDigits: 2,
                      })}
                  </div>
                  <div
                    className="text-gray-400 text-xs text-medium"
                    style={{ fontSize: 10 }}
                  >
                    Txn Fee -{" "}
                    {"₹ " +
                      parseFloat(item?.nextRepaymentAmountTxn).toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                  </div>
                </div>
              </div>
              <div
                className="flex flex-1 py-4 ml-2"
                style={{ border: "1px dotted #eba300", borderRadius: "6px" }}
                onClick={() => {
                  setForeclosureAmount(true);
                  setIsOtherAmount(false);
                }}
              >
                <div
                  className="pl-5"
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {foreclosureAmount ? (
                    <IoRadioButtonOn size={25} color="#eba300" />
                  ) : (
                    <div className="radio-icon"></div>
                  )}
                </div>
                <div className="ml-5">
                  <div className="text-base font-bold font-black">
                    Forclosure Amount
                  </div>
                  <div className="text-md font-medium text-black-500">
                    {"₹ " +
                      parseFloat(item?.foreclosureAmountWithTxn).toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                  </div>
                  <div
                    className="text-gray-400 text-xs text-medium"
                    style={{ fontSize: 10 }}
                  >
                    Txn Fee -{" "}
                    {"₹ " +
                      parseFloat(item?.foreclosureAmountTxn).toLocaleString(
                        "en-IN",
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="flex flex-1 py-4"
              style={{ border: "1px dotted #eba300", borderRadius: "6px" }}
              onClick={() => {
                setForeclosureAmount(false);
                setIsOtherAmount(true);
              }}
            >
              <div
                className="pl-5"
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isOtherAmount ? (
                  <IoRadioButtonOn size={25} color="#eba300" />
                ) : (
                  <div className="radio-icon"></div>
                )}
              </div>
              <div className="ml-5">
                <div className="text-base font-bold font-black">
                  Custom Amount
                </div>
                <div className="flex items-center text-sm font-normal font-gray-500">
                  ₹
                  <input
                    type="text"
                    className="custom-input-address ml-1 mt-0"
                    required
                    value={customAmount}
                    onChange={(e) => setCustomAmount(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="flex">
              <div
                className="flex flex-1 items-center pl-5 py-4 mr-2"
                style={{ border: "1px dotted #eba300", borderRadius: "6px" }}
              >
                <CopyToClipboard
                  text={`Acc Number: ${item?.va_account_no}, IFSC Code: ${item.va_ifsc}`}
                  onCopy={() => {
                    alert("Copied");
                  }}
                >
                  <VscCopy size={25} color="#eba300" />
                </CopyToClipboard>
                <div className="ml-5">
                  <div className="mt-1 text-base font-bold font-black">
                    Account Details
                  </div>
                  <div className="text-sm font-normal text-gray-500 mt-2">
                    {"ACC- " + (item ? item.va_account_no : "")}
                  </div>
                  <div className="text-sm font-normal text-gray-500 mt-1">
                    {"IFSC- " + (item ? item.va_ifsc : "")}
                  </div>
                </div>
              </div>
              <div
                className="flex flex-1 items-center pl-6 py-4 ml-2"
                style={{ border: "1px dotted #eba300", borderRadius: "6px" }}
              >
                <CopyToClipboard
                  text={item?.razorPayLink}
                  onCopy={() => {
                    alert("Copied");
                  }}
                >
                  <VscCopy size={25} color="#eba300" />
                </CopyToClipboard>
                <div className="ml-5">
                  <div className="mt-1 text-base font-bold font-black">
                    Razorpay Link
                  </div>
                  <div className="text-sm font-normal text-gray-500 mt-2">
                    {item
                      ? item.razorPayLink
                        ? item.razorPayLink
                        : "Link Not generated yet"
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center p-6 space-x-2">
            <button onClick={sendLink} className="upload-button">
              {loader ? <Loader size={30} /> : "Send Link"}
            </button>
            <button
              onClick={() => {
                setShowModal(false);
              }}
              type="button"
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default PaymentModal;
