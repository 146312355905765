import { AuthInstance } from "../api/ApiRepository";
export const GET_ALL_USERS = "GET_ALL_USERS";

export const getAllUsersAPI = () => {
  return async (dispatch) => {
    console.log("in_book_action");
    const res = await AuthInstance.post("auth/getAllUsers", {});
    if (res) {
      console.log("data_is_here");
      dispatch({
        type: GET_ALL_USERS,
        payload: res.data,
      });
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
    }
  };
};

export const getReportData = async (data) => {
  const res = await AuthInstance.post("api/get-collection-report", data);
  if (res) {
    return res;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};
export const getBtReportData = async (data) => {
  const res = await AuthInstance.post("api/app/fetch-bucket-data", data);
  if (res) {
    return res;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};

export const getgeneratedReports = async (page) => {
  const res = await AuthInstance.get(
    `api/fetch-downloadable-reports/?page_no=${page}`
  );
  if (res) {
    return res;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};
export const getReportLink = async (data) => {
  const res = await AuthInstance.post("api/download-collection-report", data);
  if (res) {
    return res;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};
export const downloadAnyReport = async (data) => {
  const res = await AuthInstance.post("api/reports-generation", data);
  if (res) {
    return res;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};

export const getBtReportLink = async (data) => {
  const res = await AuthInstance.post("api/download-bucket-report", data);
  if (res) {
    return res;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};

export const getLmsClientList = async (data) => {
  const res = await AuthInstance.post("api/app/lmsClientList", data);
  if (res) {
    return res.data;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};
export const getAllLoans = async ({ id, loanStatus }) => {
  const res = await AuthInstance.post("api/app/fetchLoans", {
    lmsClientId: id,
    status: loanStatus,
  });
  if (res) {
    return res.data;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};

export const getLoanDetails = async (id) => {
  const res = await AuthInstance.post("api/app/fetchLoanDetails", {
    masterAccountId: id,
  });
  if (res) {
    return res.data;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};

export const addUserApi = async (data) => {
  try {
    console.log("in_add_user_action");
    const res = await AuthInstance.post("auth/signup", data);
    console.log("add_user_res", res);
    return res;
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};

export const updateUserAPI = async (data) => {
  try {
    console.log("in_add_user_action");
    const res = await AuthInstance.post("auth/updateUser", data);
    console.log("add_user_res", res);
    return res;
  } catch (error) {
    console.log("errorrororor", error);
    return null;
  }
};
