import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import Moment from "moment";
import { KnowlarityCallAPI } from "../redux/actions/BucketAction";
import Loader from "../assets/lottie/Loader.jsx";

const ContactModal = ({ setShowModal, showModal, item }) => {
  const [showNumber, setshowNumber] = useState(false);
  const [loader, setLoader] = useState(false);

  console.log(item);

  const KnowlarityCall = async () => {
    setLoader(true);
    const res = await _KnowlarityAPI(item.locAccountId, item.lmsClientId);
    // const res = true;
    console.log("Response_kk------->", res);
    if (res) {
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const _KnowlarityAPI = async (locID, lmsID) => {
    const formdata = {
      locId: locID,
      lmsClientId: lmsID,
    };
    const res = await KnowlarityCallAPI(formdata);
    console.log("know___", res);
    return res;
  };

  const openWhatsapp = async () => {
    const cashfreeMessage =
      " Dear " +
      item.displayName +
      ", \n \n Please deposit the interest amount of Rs. " +
      parseFloat(item.nextRepaymentAmount).toFixed(2) +
      " for the loan amount Rs. " +
      item.principalAmount +
      " before " +
      Moment(item.nextRepaymentDate).format("LL") +
      " in Ruptok's bank account details given below: \n *A/c Holder Name* - Ruptok \n \n *A/c Number* - " +
      item.va_account_no +
      " \n *IFSC* - " +
      item.va_ifsc +
      " \n \n  - Ruptok";
    const razorpayMessage =
      " Dear " +
      item.displayName +
      ", \n \n Please deposit the interest amount of Rs. " +
      item.nextRepaymentAmount +
      " for the loan amount Rs. " +
      item.principalAmount +
      " before " +
      Moment(item.nextRepaymentDate).format("LL") +
      " either on the payment link or in Ruptok's bank account details given below: \n \n *A/c Holder Name* - Ruptok \n  *A/c Number* - " +
      item.va_account_no +
      " \n *IFSC* - " +
      item.va_ifsc +
      " \n \n  *OR* \n \n *Payment Link* - " +
      item.razorPayLink +
      " \n \n - Ruptok";

    let url =
      "whatsapp://send?text=" +
      (item.razorPayLink && item.razorPayLink !== ""
        ? razorpayMessage
        : cashfreeMessage) +
      "&phone=91" +
      item.mobileNo;

    window
      .open(url)
      .then((data) => {
        console.log("WhatsApp Opened successfully " + data); //<---Success
      })
      .catch(() => {
        alert("Make sure WhatsApp installed on your device"); //<---Error
      });
  };

  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal relative bg-white rounded-lg shadow p-3">
          <div className="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-black-900">
              Please Choose Option
            </h3>
            <RxCross2
              size={30}
              onClick={() => {
                setShowModal(false);
              }}
            />
          </div>
          {/* ---------- main body--------- */}
          <div
            className="flex justify-between items-center p-5 mt-3 mx-auto"
            style={{ width: "80%" }}
          >
            <div
              className="flex flex-col items-center justify-center text-lg font-medium text-black-800"
              onClick={openWhatsapp}
            >
              <FaWhatsapp size={40} className="mb-2" color="green" />
              WhatsApp
            </div>
            {showNumber ? (
              <div
                className="flex flex-col items-center justify-center text-lg font-medium text-black-800"
                onClick={() => {
                  setshowNumber(false);
                }}
              >
                {item.mobileNo}
              </div>
            ) : (
              <div
                className="flex flex-col items-center justify-center text-lg font-medium text-black-800"
                onClick={() => {
                  setshowNumber(true);
                }}
              >
                <IoCallOutline size={40} className="mb-2" color="blue" />
                Direct Call
              </div>
            )}
            {loader ? (
              <Loader />
            ) : (
              <div
                className="flex flex-col items-center justify-center text-lg font-medium text-black-800"
                onClick={KnowlarityCall}
              >
                <MdOutlineWifiCalling3
                  size={40}
                  className="mb-2"
                  color="green"
                />
                Virtual Call
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default ContactModal;
