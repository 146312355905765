import { Switch } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUserAPI, getAllUsersAPI } from "../redux/actions/AdminAction";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

const UpdateUserModal = ({ setShowModal, showModal, roles, departments }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userData, setUserData] = useState(null);
  const [isEnabled, setIsEnabled] = useState();

  const [role, setRole] = useState("");
  const [department, setDepartment] = useState([]);

  const getUsers = () => dispatch(getAllUsersAPI());

  useEffect(() => {
    const user = localStorage.getItem("updateUser")
      ? JSON.parse(localStorage.getItem("updateUser"))
      : null;
    console.log("User", user);
    if (user !== null) {
      setUserData(user);
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setPhone(user.phone);
      setIsEnabled(user.is_active ? true : false);
      setRole(user.roleId);
      setDepartment(user.deptId);
    }
  }, [showModal]);

  const update = async () => {
    const raw = {
      id: userData.id,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      is_active: isEnabled ? 1 : 0,
      roleId: role,
      deptId: department,
    };
    const res = await updateUserAPI(raw);
    if (res) {
      setShowModal(false);
      getUsers();
      console.log("Response-------->", res);
    }
  };

  if (showModal) {
    return (
      <div className="custom-modal-container overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal">
        <div className="custom-modal relative bg-white rounded-lg shadow">
          <div className="flex justify-between items-center p-4 rounded-t border-b dark:border-gray-600">
            <h3 className="text-xl font-semibold text-black-900">
              Update User
            </h3>
            <div className="flex items-center text-black-900 text-xl font-semibold">
              Status
              <Switch
                checked={isEnabled}
                className="ml-2"
                onChange={(e) => {
                  setIsEnabled(e.target.checked);
                }}
              />
            </div>
          </div>
          {/* ---------- main body--------- */}
          <div className="flex flex-col justify-center px-5 pb-5">
            <div className="flex">
              <input
                type="text"
                className="custom-input-address mr-4"
                placeholder="First Name"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <input
                type="text"
                className="custom-input-address ml-4"
                placeholder="Last Name"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="flex">
              <input
                type="text"
                className="custom-input-address mr-4"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                className="custom-input-address ml-4"
                placeholder="Phone Number"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="flex w-full items-center justify-between mt-8">
              <FormControl style={{ width: "48%" }}>
                <InputLabel>Select Role</InputLabel>
                <Select
                  value={role}
                  label="Select Role"
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                >
                  {roles?.map((i) => (
                    <MenuItem value={i.id}>{i.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: "48%" }}>
                <InputLabel>Select Department</InputLabel>
                <Select
                  multiple
                  value={department}
                  label="Select Department"
                  onChange={(e) => {
                    setDepartment(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  {departments?.map((i) => (
                    <MenuItem value={i.id}>{i.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="flex items-center p-6 space-x-2">
            <button onClick={update} className="upload-button">
              Update
            </button>
            <button
              onClick={() => {
                setShowModal(false);
                localStorage.setItem("updateUser", null);
              }}
              type="button"
              className="cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default UpdateUserModal;
