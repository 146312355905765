import React, { useEffect, useState } from "react";
import { getLmsClientList } from "../../../redux/actions/AdminAction";
import PaginationClassic from "../../../components/PaginationClassic";
import { FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { BiSearch } from "react-icons/bi";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import Loader from "../../../assets/lottie/Loader.jsx";

const Customers = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getLmsClientList({ page_no: page, search: search }).then((res) => {
      setData(res.data.collectionData);
      setPage(res.data.page_no);
      setTotalPage(res.data.total_page);
      setTotalItems(res.data.total_records);
    });
  }, [page, search]);

  return (
    <div className="flex">
      <Sidebar />
      <div>
        <Header>
          <div className="w-full flex justify-between items-center">
            <h2 className="font-bold flex text-xl text-slate-800">
              LMS Client List
              <div className="text-gray-500 ml-4">({totalItems})</div>
            </h2>
            <div className="flex justify-end items-center">
              <FormControl
                sx={{ width: "300px", marginLeft: "10px" }}
                variant="outlined"
              >
                <InputLabel>Name and Account Number</InputLabel>
                <OutlinedInput
                  type="text"
                  endAdornment={<BiSearch size={30} />}
                  label="Name and Account Number"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </FormControl>
            </div>
          </div>
        </Header>
        <div className="m-5 mt-3">
          <div className="flex flex-col white-card p-8">
            <div className="flex flex flex-wrap w-full justify-between">
              <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <main>
                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
                    <div>
                      {/* Table */}
                      <div
                        className="overflow-x-auto"
                        style={{
                          width: window.innerWidth > 1400 ? "80vw" : "70vw",
                        }}
                      >
                        {data === null ? (
                          <Loader size={200} />
                        ) : (
                          <table className="table-auto w-full">
                            {/* Table header */}
                            <thead className="text-xs font-bold uppercase text-black-800 bg-slate-50 border-t border-b border-slate-200">
                              <tr>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left hover:bg-slate-50">
                                    Account Number
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Client Name
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    lms Client Id
                                  </div>
                                </th>
                                {/* <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                <div className="font-bold text-left">
                                  External Id
                                </div>
                              </th> */}
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Status
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            {data?.map((customer) => {
                              return (
                                <tbody className="text-sm divide-y divide-slate-200">
                                  <tr
                                    onClick={() => {
                                      navigate(
                                        `/allloans/${customer.lmsClientId}`
                                      );
                                    }}
                                    className="hover:bg-slate-100"
                                    key={customer.accountNo}
                                  >
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left text-sky-500">
                                        {customer.accountNo}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.displayName}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-sky-500">
                                          {customer?.lmsClientId}
                                        </div>
                                      </div>
                                    </td>
                                    {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="flex items-center">
                                      <div className="font-medium text-slate-500">
                                        {customer?.externalId}
                                      </div>
                                    </div>
                                  </td> */}
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.status}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </table>
                        )}
                        {data?.length === 0 ? (
                          <header className="px-5 py-4">
                            <h2 className="font-semibold text-center text-slate-800">
                              No Matches found
                            </h2>
                          </header>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div className="m-3">
            <PaginationClassic
              page={parseInt(page)}
              setPage={setPage}
              totalPages={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
