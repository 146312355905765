import {
  SET_LOC_ACCOUNTS_DETAILS,
  SET_CUSTOMER_LOC_ACCOUNTS,
} from "../actions/BucketAction";

const initialState = {
  locTransactions: [],
  locActivityDetails: [],
  customerLocAccounts: [],
};

function locAccountReducer(state = initialState, action) {
  console.log("loc_reducer");
  switch (action.type) {
    case SET_LOC_ACCOUNTS_DETAILS:
      console.log(
        "In_all_transactions_case_offset",
        action.payload.transactionsData
      );
      return {
        ...state,
        locTransactions: action.payload.transactionsData,
        locActivityDetails: action.payload.activityDetails,
      };
    case SET_CUSTOMER_LOC_ACCOUNTS:
      console.log("In_all_transactions_case_offset_loc", action.payload.data);
      return { ...state, customerLocAccounts: action.payload.data };
    default:
      return state;
  }
}

export default locAccountReducer;
