import React, { useState } from "react";
import { loginUserAPI } from "../../redux/actions/AuthAction";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import lock from "../../assets/img/red-lock.png";

const OTP = ({ phone }) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");

  const hitLoginAPI = (data) => dispatch(loginUserAPI(data));

  return (
    <>
      <div className="font-bold text-xl mb-8 text-center">
        Verify Phone number
      </div>
      <img src={lock} alt="" />
      <div className="font-bold text-xl mt-4 mb-2 text-center">Enter Code</div>
      <div className="light-grey-text mb-6">We sent code to {phone}</div>
      <OtpInput
        value={otp}
        onChange={(otp) => {
          setOtp(otp);
        }}
        numInputs={6}
        className="mb-10"
        inputStyle={{
          height: 50,
          width: 50,
          margin: 10,
          background: "#F4F4F4",
          border: "0.4px solid #ECECEC",
          borderRadius: 5,
          marginBottom: "20px",
        }}
      />
      <button
        onClick={(e) => {
          e.preventDefault();

          if (otp === null || otp.length !== 6) {
            console.log("OTP is not valid");
          } else {
            const formData = {
              phone: phone,
              otp: otp,
            };
            const res = hitLoginAPI(formData);
            console.log("opt is valid", res);
          }
        }}
        className="next-auth-button"
        disabled={otp?.length === 6 ? false : true}
      >
        Next
      </button>
    </>
  );
};

export default OTP;
