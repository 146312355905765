import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBucketStats,
  getTodayStats,
  getRecentTransactions,
} from "../../../redux/actions/TransactionsAction";
import { getLmsClientList } from "../../../redux/actions/AdminAction";
import Moment from "moment";
import { useNavigate } from "react-router";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import Loader from "../../../assets/lottie/Loader.jsx";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const RecentTransactions = (data) => dispatch(getRecentTransactions(data));
  const GetTodayStats = () => dispatch(getTodayStats());
  const GetBucketStats = () => dispatch(getBucketStats());
  const { recentTransactions, todayStats, bucketStats } = useSelector(
    (state) => state.transactionReducer
  );

  const [data, setdata] = useState(null);

  const { user } = useSelector((state) => state.authReducer);
  console.log("user_______>", user);

  useEffect(() => {
    RecentTransactions({});
    GetTodayStats();
    GetBucketStats();
    getLmsClientList().then((res) => {
      setdata(res.data);
    });
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full">
        <Header>
          <div className="text-xl font-medium font-black">
            Hi, {user?.first_name ? user.first_name : "User"} {user?.last_name}{" "}
            👋
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-base text-gray-400 mt-2">
              {user?.phone ? `+91 ${user.phone}` : null}
            </div>
            <div className="text-sm font-base text-gray-400 mt">
              {user?.email}
            </div>
          </div>
        </Header>
        <div className="m-5">
          <div className="white-card-inner flex flex-wrap py-10">
            <div
              className="w-full flex flex-1 flex-col"
              style={{ minWidth: "500px" }}
            >
              <div className="white-card mr-2 mt-4">
                <div className="w-full">
                  <div className="text-xl font-bold font-black">
                    Today's Overview
                  </div>
                  {todayStats !== null ? (
                    <div style={{ height: 200 }}>
                      <div className="flex items-center justify-between w-full mt-4">
                        <div className="white-card mt-4">
                          <div className="text-center text-black font-extrabold text-xl">
                            {"₹ " +
                              parseFloat(
                                todayStats ? todayStats?.todaysCollection : ""
                              ).toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })}
                          </div>
                          <div className="text-center text-gray-500 text-sm font-medium mt-1">
                            Collection
                          </div>
                        </div>
                        <div className="white-card mt-4 ml-4">
                          <div className="text-center text-black font-extrabold text-xl">
                            {todayStats ? todayStats?.totalLoc : ""}
                          </div>
                          <div className="text-center text-gray-500 text-sm font-medium mt-1">
                            No. of loan A/C
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full mb-4">
                        <div className="white-card mt-4">
                          <div className="text-center text-black font-extrabold text-xl">
                            {"₹ " +
                              parseFloat(
                                todayStats ? todayStats?.totalInterestDue : ""
                              ).toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })}
                          </div>
                          <div className="text-center text-gray-500 text-sm font-medium mt-1">
                            Total interest dues
                          </div>
                        </div>
                        <div className="white-card mt-4 ml-4">
                          <div className="text-center text-black font-extrabold text-xl">
                            {"₹ " +
                              parseFloat(
                                todayStats ? todayStats?.totalNPACollection : ""
                              ).toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })}
                          </div>
                          <div className="text-center text-gray-500 text-sm font-medium mt-1">
                            NPA amount recovered
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Loader size={100} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col white-card mr-2 mt-4 pb-6 pt-4">
                <div className="flex items-center justify-between w-full mb-2">
                  <div className="w-1/3 text-center"></div>
                  <div className="text-black text-xl font-bold w-1/3 text-center">
                    Today Due
                  </div>
                  <div className="text-black text-xl font-bold w-1/3 text-center">
                    Total Due
                  </div>
                </div>

                {bucketStats !== null ? (
                  <div style={{ height: 160 }}>
                    <div className="flex items-center justify-between w-full mt-3">
                      <div className="w-1/3 text-black text-xl font-bold flex items-center">
                        <div className="box1 mr-4"></div>
                        btx
                      </div>
                      <div className="w-1/3 text-center text-gray-500 text-sm font-medium">
                        {"₹ " +
                          parseFloat(bucketStats?.btx.todayDue).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                      <div className="w-1/3 text-center text-gray-500 text-sm font-medium">
                        {"₹ " +
                          parseFloat(bucketStats?.btx.totalDue).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full mt-3">
                      <div className="w-1/3 text-black text-xl font-bold flex items-center">
                        <div className="box2 mr-4"></div>
                        bt1
                      </div>
                      <div className="w-1/3 text-center text-gray-500 text-sm font-medium">
                        {"₹ " +
                          parseFloat(bucketStats?.bt1.todayDue).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                      <div className="w-1/3 text-center text-gray-500 text-sm font-medium">
                        {"₹ " +
                          parseFloat(bucketStats?.bt1.totalDue).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full mt-3">
                      <div className="w-1/3 text-black text-xl font-bold flex items-center">
                        <div className="box3 mr-4"></div>
                        bt2
                      </div>
                      <div className="w-1/3 text-center text-gray-500 text-sm font-medium">
                        {"₹ " +
                          parseFloat(bucketStats?.bt2.todayDue).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                      <div className="w-1/3 text-center text-gray-500 text-sm font-medium">
                        {"₹ " +
                          parseFloat(bucketStats?.bt2.totalDue).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full mt-3">
                      <div className="w-1/3 text-black text-xl font-bold flex items-center">
                        <div className="box4 mr-4"></div>
                        bt3
                      </div>
                      <div className="w-1/3 text-center text-gray-500 text-sm font-medium">
                        {"₹ " +
                          parseFloat(bucketStats?.bt3.todayDue).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                      <div className="w-1/3 text-center text-gray-500 text-sm font-medium">
                        {"₹ " +
                          parseFloat(bucketStats?.bt3.totalDue).toLocaleString(
                            "en-IN",
                            {
                              maximumFractionDigits: 2,
                            }
                          )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: 160,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Loader size={100} />
                  </div>
                )}
              </div>
              <div className="flex flex-col white-card mr-2 mt-4 pt-4">
                <div className="flex w-full items-center justify-between">
                  <div className="text-xl flex font-bold font-black mb-4">
                    LMS Clients
                    <div className="text-gray-500 ml-4">
                      ({data?.total_records})
                    </div>
                  </div>
                  <div
                    className="text-sm font-medium text-yellow mb-4 cursor-default w-1/6 flex items-center justify-center pl-0"
                    onClick={() => {
                      navigate("/customers");
                    }}
                  >
                    View All
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  {data
                    ? data.collectionData.slice(0, 1).map((i) => (
                        <div className="flex items-center flex-col w-full white-card">
                          <div className="flex items-center justify-between w-full">
                            <div className="w-full text-black font-bold flex items-center text-base font-medium">
                              {i?.displayName}
                            </div>
                          </div>
                          <div className="flex items-center justify-between w-full">
                            <div className="w-1/2 text-gray-500 text-xs text-medium">
                              Acc No: {i?.accountNo}
                            </div>
                            <div className="w-1/2 text-right text-gray-500 text-xs text-medium">
                              {i?.status}
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
            <div
              className="flex flex-col flex-1 white-card ml-0 lg:ml-5 mt-5 lg:mt-0"
              style={{ minWidth: "400px" }}
            >
              <div className="flex w-full items-center justify-between">
                <div className="text-xl font-bold flex font-black mb-4">
                  Recent Transactions
                  <div className="text-gray-500 ml-4">
                    ({recentTransactions?.length})
                  </div>
                </div>
                <div
                  className="text-sm font-medium text-yellow mb-4 cursor-default w-1/6 flex items-center justify-center pl-0"
                  onClick={() => {
                    navigate("/transactions");
                  }}
                >
                  View All
                </div>
              </div>
              <div className="flex flex-col w-full">
                {recentTransactions?.length !== 0 ? (
                  recentTransactions?.slice(0, 7).map((i) => (
                    <div className="flex items-center flex-col w-full mt-3 white-card">
                      <div className="flex items-center justify-between w-full">
                        <div className="w-1/2 text-black font-bold flex items-center text-base font-medium">
                          {i?.displayName}
                        </div>
                        <div className="w-1/2 text-right text-black text-lg font-bold">
                          {"₹ " +
                            parseFloat(i?.amount).toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                            })}
                        </div>
                      </div>
                      <div className="flex items-center justify-between w-full">
                        <div className="w-1/2 text-gray-500 text-xs text-medium">
                          LOC ID: {i?.locAccountId}
                        </div>
                        <div className="w-1/2 text-right text-gray-500 text-xs text-medium">
                          {Moment(i.transactionDate).format("LL")}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <Loader size={200} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
