import { AuthInstance } from "../api/ApiRepository";
export const GET_RECENT_TRANSACTIONS = "GET_RECENT_TRANSACTIONS";
export const GET_ALL_TRANSACTIONS = "GET_ALL_TRANSACTIONS";
export const TODAY_STATS = "TODAY_STATS";
export const BUCKET_STATS = "BUCKET_STATS";

export const getRecentTransactions = (data) => {
  try {
    return async (dispatch) => {
      console.log("in_book_action");
      const res = await AuthInstance.get(
        "api/app/get-recent-transactions?offset=0",
        {}
      );
      if (res) {
        console.log("data_is_here");
        dispatch({
          type: GET_RECENT_TRANSACTIONS,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getAllTransactions = (data) => {
  try {
    return async (dispatch) => {
      console.log("in_book_action");
      const offset = data ? data : 0;
      const res = await AuthInstance.get(
        "api/app/get-recent-transactions?offset=" + offset,
        {}
      );
      if (res) {
        console.log("data_is_here");
        // const data = res.data;
        // data.offset = offset;
        // dispatch({
        //   type: GET_ALL_TRANSACTIONS,
        //   payload: data
        // });
        return res.data.data;
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
        return [];
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getTodayStats = () => {
  try {
    return async (dispatch) => {
      console.log("in_today_action");
      const res = await AuthInstance.get("api/app/get-today-stats", {});
      if (res) {
        console.log("data_is_here_stats", res.data.data);
        dispatch({
          type: TODAY_STATS,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};

export const getBucketStats = () => {
  try {
    return async (dispatch) => {
      console.log("in_today_action");
      const res = await AuthInstance.get("api/app/bucket-wise-stats", {});
      if (res) {
        console.log("data_is_here_stats", res.data.data);
        dispatch({
          type: BUCKET_STATS,
          payload: res.data,
        });
      } else {
        console.log("Unable to fetch data from the API BASE URL!");
      }
    };
  } catch (error) {
    console.log("errorrororor", error);
  }
};
