import React, { useEffect, useState } from "react";
import Moment from "moment";
import { GetBucketDataAPI } from "../../../redux/actions/BucketAction";
import Loader from "../../../assets/lottie/Loader.jsx";
import { IoPin, IoAlarmOutline } from "react-icons/io5";
import { VscCallOutgoing } from "react-icons/vsc";
import { RxLightningBolt } from "react-icons/rx";
import { Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PaymentModal from "../../../components/PaymentModal";
import SnoozeModal from "../../../components/SnoozeModal";
import ContactModal from "../../../components/ContactModal";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useSelector } from "react-redux";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";

const Pending = () => {
  const [data, setData] = useState([]);
  const [Contactdata, setContactData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [bucketID, setBucketID] = useState("all");
  const [paymentModal, setPaymentModal] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [snoozeModal, setSnoozeModal] = useState(false);

  const [repaymentDate, setRepaymentDate] = useState(new Date());
  const [repaymentDateStatus, setRepaymentDateStatus] = useState(false);

  const { user } = useSelector((state) => state.authReducer);
  const roles = user?.roles?.map((i) => i?.departmentId);

  const navigate = useNavigate();

  useEffect(() => {
    bucketData();
  }, [searchValue, bucketID, repaymentDate]);

  const bucketData = async () => {
    setLoader(true);
    const formdata = {
      bucket: bucketID,
      search: searchValue,
      repaymentDateStatus: repaymentDateStatus,
      repaymentDate: Moment(repaymentDate).format("YYYY-MM-DD"),
      offset: 0,
    };
    const res = await GetBucketDataAPI(formdata);
    setLoader(false);
    setData(res);
  };

  const _BucketTypes = [
    { id: "all", value: "All" },
    { id: "btx", value: "Btx" },
    { id: "bt1", value: "Bt1" },
    { id: "bt2", value: "Bt2" },
    { id: "bt3", value: "Bt3" },
  ];
  return (
    <div className="flex">
      <Sidebar />
      <div className="w-full">
        <Header>
          <div className="flex w-full items-center justify-between">
            <h2 className="font-bold text-xl text-slate-800">
              Pending collections
            </h2>
            <div className="flex items-center">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date"
                  inputFormat="DD/MM/YYYY"
                  value={repaymentDate}
                  onChange={(e) => {
                    setRepaymentDate(e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                className="flex-1 mx-4"
                style={{ width: "100px", height: "55px" }}
                label="Type"
                value={bucketID}
                onChange={(e) => setBucketID(e.target.value)}
              >
                {_BucketTypes?.map((i) => (
                  <MenuItem value={i.id}>{i.value}</MenuItem>
                ))}
              </Select>
              <div
                class="relative text-gray-600"
                style={{ width: "280px", height: "55px" }}
              >
                <input
                  style={{ width: "280px", height: "55px" }}
                  class="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-xs focus:outline-none"
                  name="search"
                  placeholder="Search customer name or LOC ID"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
                <button type="submit" class="absolute right-0 top-0 mt-5 mr-4">
                  <svg
                    class="text-gray-600 h-4 w-4 fill-current"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    width="512px"
                    height="512px"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </Header>
        <div className="m-5">
          <div className="flex flex-col flex-1 white-card-inner">
            <div className="flex flex-col w-full">
              {loader ? (
                <Loader size={200} />
              ) : data ? (
                data?.length === 0 ? (
                  <div className="text-xl font-bold font-black mb-4 text-center">
                    No Data Found
                  </div>
                ) : (
                  data?.map((item) => (
                    <div className="flex items-center w-full mt-3 white-card">
                      <div
                        className="w-full"
                        onClick={() => {
                          navigate(
                            `/leaddetails/${item?.locAccountId}/${item?.lmsClientId}`
                          );
                          localStorage.setItem(
                            "leaddetails",
                            JSON.stringify(item)
                          );
                        }}
                      >
                        <div
                          className="flex items-center justify-between w-full pb-4"
                          style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                        >
                          <div>
                            <div className="text-yellow-800 font-bold flex items-center text-sm xl:text-lg">
                              {item.displayName ? item.displayName : ""}
                            </div>
                            <div className="text-gray-400 font-medium flex items-center text-sm">
                              {item.mobileNo ? item.mobileNo : ""}
                            </div>
                          </div>
                          <div className="text-black font-medium flex items-center text-sm xl:text-base uppercase">
                            <IoPin className="mr-2" size={20} color="black" />{" "}
                            {item.city ? item.city : ""}
                          </div>
                        </div>
                        <div className="flex items-center justify-between w-full mt-6">
                          <div className="flex flex-col w-1/4">
                            <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium">
                              {"₹ " +
                                parseFloat(
                                  item?.foreclosureAmountWithTxn
                                ).toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                            </div>
                            <div
                              className="text-gray-400 text-xs text-medium"
                              style={{ fontSize: 10 }}
                            >
                              Txn Fee -{" "}
                              {"₹ " +
                                parseFloat(
                                  item?.foreclosureAmountTxn
                                ).toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                            </div>
                            <div className="text-gray-500 text-xs text-medium">
                              Total Outstanding
                            </div>
                          </div>
                          <div className="flex flex-col items-center w-1/4">
                            <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium ">
                              {Moment(item.activatedOnDate).format("LL")}
                            </div>
                            <div className="text-gray-500 text-xs text-medium">
                              Loan Start Date
                            </div>
                          </div>
                          <div className="flex flex-col items-center w-1/4">
                            <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium">
                              {item.nextRepaymentDate === null
                                ? "No repayment"
                                : Moment(item.nextRepaymentDate).format("LL")}
                            </div>
                            <div className="text-gray-500 text-xs text-medium ">
                              Next Repayment Date
                            </div>
                          </div>
                          <div className="flex flex-col w-1/4">
                            <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium ml-auto">
                              {Moment(item.lastActiveTransactionDate).format(
                                "LL"
                              )}
                            </div>
                            <div className="text-gray-500 text-xs text-medium ml-auto">
                              Last Payment Date
                            </div>
                          </div>
                        </div>
                        <div
                          className="flex items-center justify-between w-full mt-6 pb-4"
                          style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
                        >
                          <div className="flex flex-col w-1/4">
                            <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium">
                              {"₹ " +
                                parseFloat(
                                  item?.lastTransactionAmount
                                ).toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                            </div>
                            <div className="text-gray-500 text-xs text-medium">
                              Last Payment Amount
                            </div>
                          </div>
                          <div className="flex flex-col items-center w-1/4">
                            <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium">
                              {"₹ " +
                                parseFloat(
                                  item?.nextRepaymentAmountWithTxn
                                ).toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                            </div>
                            <div
                              className="text-gray-400 text-xs text-medium"
                              style={{ fontSize: 10 }}
                            >
                              Txn Fee -{" "}
                              {"₹ " +
                                parseFloat(
                                  item?.nextRepaymentAmountTxn
                                ).toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                            </div>
                            <div className="text-gray-500 text-xs text-medium">
                              Next RePayment Amount
                            </div>
                          </div>
                          <div className="flex flex-col items-center w-1/4">
                            <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium ">
                              {"₹ " +
                                parseFloat(
                                  item?.principalAmount
                                ).toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                            </div>
                            <div className="text-gray-500 text-xs text-medium">
                              Loan Amount
                            </div>
                          </div>
                          <div className="flex flex-col w-1/4">
                            <div className="text-black font-bold flex items-center text-sm xl:text-base font-medium ml-auto">
                              {data?.agent_firstname
                                ? data?.agent_firstname
                                : "No one"}
                            </div>
                            <div className="text-gray-500 text-xs text-medium ml-auto">
                              Contacted By
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center justify-between w-full pt-4">
                          <div className="flex flex-col">
                            <div className="text-yellow-500 font-extrabold flex items-center text-sm xl:text-lg font-medium ml-auto">
                              {item.savingsProductName
                                ? item.savingsProductName
                                : " "}
                            </div>
                            <div className="text-gray-500 text-xs text-medium">
                              Scheme
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <div className="text-yellow-500 font-medium flex items-center text-sm xl:text-base font-medium ml-auto">
                              {item.dispStatus ? item.dispStatus : "No Status"}
                            </div>
                            <div className="text-gray-500 text-xs text-medium ml-auto">
                              Status
                            </div>
                          </div>
                        </div>
                      </div>
                      {roles?.includes(1) ? (
                        <div
                          className="flex flex-col items-center justify-between ml-4 xl:ml-10 pl-2 xl:pl-5"
                          style={{
                            width: "12%",
                            height: "250px",
                            borderLeft: "1px solid rgba(120,120,120,0.2)",
                          }}
                        >
                          <div
                            className="flex h-1/3 flex-col items-center justify-center text-medium text-xs xl:text-sm"
                            style={{ color: "green", textAlign: "center" }}
                            onClick={() => {
                              setContactModal(true);
                              setContactData(item);
                            }}
                          >
                            <div
                              style={{
                                height: "25px",
                                width: "25px",
                                backgroundColor: "rgba(120,120,120,0.1)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "5px",
                                borderRadius: "6px",
                              }}
                            >
                              <VscCallOutgoing color="green" size={15} />
                            </div>
                            Contact
                          </div>
                          <div
                            className="flex h-1/3 flex-col items-center justify-center text-medium text-xs xl:text-sm"
                            style={{ color: "red", textAlign: "center" }}
                            onClick={() => {
                              setContactData(item);
                              setPaymentModal(true);
                            }}
                          >
                            <div
                              style={{
                                height: "25px",
                                width: "25px",
                                backgroundColor: "rgba(120,120,120,0.1)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "5px",
                                borderRadius: "6px",
                              }}
                            >
                              <RxLightningBolt color="red" size={15} />
                            </div>
                            Payment link
                          </div>
                          <div
                            className="flex h-1/3 flex-col items-center justify-center text-medium text-xs xl:text-sm"
                            style={{ color: "#6d5fd1", textAlign: "center" }}
                            onClick={() => {
                              setSnoozeModal(true);
                              setContactData(item);
                            }}
                          >
                            <div
                              style={{
                                height: "25px",
                                width: "25px",
                                backgroundColor: "rgba(120,120,120,0.1)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "5px",
                                borderRadius: "6px",
                              }}
                            >
                              <IoAlarmOutline color="#6d5fd1" size={15} />
                            </div>
                            Snooze
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))
                )
              ) : null}
            </div>
          </div>
          <ContactModal
            showModal={contactModal}
            setShowModal={setContactModal}
            item={Contactdata}
          />
          <PaymentModal
            showModal={paymentModal}
            setShowModal={setPaymentModal}
            item={Contactdata}
          />
          <SnoozeModal
            showModal={snoozeModal}
            setShowModal={setSnoozeModal}
            item={Contactdata}
          />
        </div>
      </div>
    </div>
  );
};

export default Pending;
