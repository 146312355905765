import React from "react";
import { useSelector } from "react-redux";
import Moment from "moment";

const LocAccounts = () => {
  const { customerLocAccounts } = useSelector(
    (state) => state.locAccountReducer
  );

  return (
    <div className="w-full flex flex-wrap justify-between">
      {customerLocAccounts?.length === 0 ? (
        <div className="text-gray-500 text-xl font-extrabold text-center mt-10 w-full flex justify-center">
          No LOC accounts found
        </div>
      ) : (
        customerLocAccounts.map((i) => (
          <div className="flex items-center flex-col mt-6 white-card-double">
            <div className="flex items-center justify-between w-full">
              <div className="w-1/2 text-gray-500 text-sm">
                LOC ID: {i?.locAccountId}
              </div>
              <div className="w-1/2 text-right text-gray-500 text-sm">
                {"Loan Date : " + Moment(i.activatedOnDate).format("LL")}
              </div>
            </div>
            <div className="flex items-center justify-between w-full">
              <div className="w-1/2 text-black-500 text-sm font-medium">
                {"₹ " +
                  (i.totalWithdrawals
                    ? parseFloat(i.totalWithdrawals).toFixed(2)
                    : "")}
              </div>
              <div
                className="w-1/2 text-right text-gray-500 text-lg font-bold"
                style={{ color: i.status === "Active" ? "green" : "red" }}
              >
                {i?.status}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default LocAccounts;
