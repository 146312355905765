import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../screens/auth/Login";

function AuthStack() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default AuthStack;
