import { AuthInstance } from "../api/ApiRepository";
export const USER_LOGIN_ACTION = "USER_LOGIN_ACTION";

export const UserLoginAPI = (data) => {
  return async (dispatch) => {
    console.log("in_book_action");
    const res = await AuthInstance.post("auth/login", data);
    if (res) {
      console.log("data_is_here", res.data.token);
      dispatch({
        type: USER_LOGIN_ACTION,
        payload: res.data,
      });
    } else {
      console.log("Unable to fetch data from the API BASE URL!");
    }
  };
};

export const getRoles = async () => {
  const res = await AuthInstance.get("api/get-roles");
  if (res) {
    return res.data;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};

export const getDepartments = async () => {
  const res = await AuthInstance.get("api/get-departments");
  if (res) {
    return res.data;
  } else {
    console.log("Unable to fetch data from the API BASE URL!");
  }
};
