import React, { useEffect, useState } from "react";
import {
  getReportData,
  getReportLink,
} from "../../../redux/actions/AdminAction";
import PaginationClassic from "../../../components/PaginationClassic";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import Moment from "moment";
import { BiSearch } from "react-icons/bi";
import { RiArrowDropDownFill, RiArrowDropUpFill } from "react-icons/ri";
import Sidebar from "../../../components/Sidebar";
import Header from "../../../components/Header";
import { toast } from "react-toastify";
import Loader from "../../../assets/lottie/Loader.jsx";

const Report = () => {
  var today = new Date();
  const [showFilters, setshowFilters] = useState(false);
  const [fromDate, setFromDate] = useState("01/01/2023");
  const [toDate, setToDate] = useState(
    `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`
  );
  const [status, setstatus] = useState(0);
  const [data, setData] = useState(null);

  const [search, setSearch] = useState("");
  const [lender, setLender] = useState(0);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [filter, setFilter] = useState(100);

  useEffect(() => {
    const formData = new FormData();
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    formData.append("page_no", page);
    formData.append("status", status);
    if (search !== "") {
      formData.append("search", search);
    }
    if (lender !== 0) {
      formData.append("lender_id", lender);
    }
    getReportData(formData).then((res) => {
      setData(res.data.data.collectionData);
      setPage(parseInt(res.data.data.page_no));
      setTotalPage(res.data.data.total_page);
    });
  }, [page, fromDate, toDate, status, search, lender]);

  const downloadReport = () => {
    const formData = new FormData();
    formData.append("fromDate", fromDate);
    formData.append("toDate", toDate);
    formData.append("status", status);
    if (search !== "") {
      formData.append("search", search);
    }
    if (lender !== 0) {
      formData.append("lender_id", lender);
    }
    getReportLink(formData).then((res) => {
      if (res.data.status === "success") {
        toast(`${res.data.data}, check reports section to download`, {
          progressStyle: { background: "#eba300" },
        });
      }
    });
  };

  const _BucketTypes = [
    { id: 100, value: "Lifetime Data" },
    { id: 0, value: "Today" },
    { id: 1, value: "Yesterday" },
    { id: 3, value: "Past 3 Days" },
    { id: 7, value: "Past 7 Days" },
    { id: 10, value: "Past 10 Days" },
    { id: 31, value: "Past Month" },
  ];
  const Statuses = [
    { id: 0, value: "All Status" },
    { id: 1, value: "Pending" },
    { id: 2, value: "Processed" },
    { id: 3, value: "Processing" },
    { id: 4, value: "Failed" },
    { id: 5, value: "Reversed" },
    { id: 6, value: "Refunded" },
  ];
  const Lenders = [
    { id: 0, value: "All Lender" },
    { id: 1, value: "CSB bank" },
    { id: 3, value: "Fincare bank" },
    { id: 5, value: "MagFinserv bank" },
    { id: 17, value: "Ruptok Capital" },
  ];

  return (
    <div className="flex">
      <Sidebar />
      <div>
        <Header height={showFilters ? "190px" : "90px"}>
          <div className="flex flex-col w-full h-full py-4 items-center justify-between">
            <div className="w-full flex justify-between items-center">
              <h2 className="font-bold text-xl text-slate-800">
                Collection report
              </h2>
              <div className="flex justify-end items-center">
                <FormControl
                  sx={{ width: "200px", marginRight: "20px" }}
                  variant="outlined"
                >
                  <InputLabel>Folder and Lan ID</InputLabel>
                  <OutlinedInput
                    type="text"
                    endAdornment={<BiSearch size={30} />}
                    label="Folder and Lan ID"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                </FormControl>
                <div
                  className="btn bg-indigo-500 text-white h-14"
                  onClick={() => {
                    downloadReport();
                  }}
                >
                  Generate CSV
                </div>
                <div
                  className="ml-4 flex items-center text-sky-500 text-sm text-medium cursor-default"
                  onClick={() => {
                    setshowFilters(!showFilters);
                  }}
                >
                  Filters
                  {showFilters ? (
                    <RiArrowDropUpFill color="blue" size={50} />
                  ) : (
                    <RiArrowDropDownFill color="blue" size={50} />
                  )}
                </div>
              </div>
            </div>
            {showFilters ? (
              <div
                className="w-full flex justify-between items-center pt-6"
                style={{ borderTop: "1px dotted gray" }}
              >
                <div style={{ width: "170px" }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="From (MM/DD/YYYY)"
                      inputFormat="MM/DD/YYYY"
                      value={fromDate}
                      onChange={(e) => {
                        var today = new Date(e);
                        setFromDate(
                          `${
                            today.getMonth() + 1
                          }/${today.getDate()}/${today.getFullYear()}`
                        );
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div style={{ marginLeft: "10px", width: "170px" }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DesktopDatePicker
                      label="To (MM/DD/YYYY)"
                      inputFormat="MM/DD/YYYY"
                      value={toDate}
                      onChange={(e) => {
                        var today = new Date(e);
                        setToDate(
                          `${
                            today.getMonth() + 1
                          }/${today.getDate()}/${today.getFullYear()}`
                        );
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <FormControl style={{ width: "150px", marginLeft: "10px" }}>
                  <InputLabel>Duration</InputLabel>
                  <Select
                    label="Duration"
                    value={filter}
                    onChange={(e) => {
                      var yesterday = new Date();
                      var today = new Date();
                      yesterday.setDate(yesterday.getDate() - e.target.value);
                      setFromDate(
                        `${
                          yesterday.getMonth() + 1
                        }/${yesterday.getDate()}/${yesterday.getFullYear()}`
                      );
                      if (e.target.value === 100) {
                        setFromDate("01/01/2023");
                        setToDate(
                          `${
                            today.getMonth() + 1
                          }/${today.getDate()}/${today.getFullYear()}`
                        );
                      } else if (e.target.value === 1) {
                        setToDate(
                          `${
                            yesterday.getMonth() + 1
                          }/${yesterday.getDate()}/${yesterday.getFullYear()}`
                        );
                      } else {
                        setToDate(
                          `${
                            today.getMonth() + 1
                          }/${today.getDate()}/${today.getFullYear()}`
                        );
                      }

                      setFilter(e.target.value);
                    }}
                  >
                    {_BucketTypes.map((i) => (
                      <MenuItem value={i.id}>{i.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl style={{ width: "150px", marginLeft: "10px" }}>
                  <InputLabel>Select Status</InputLabel>
                  <Select
                    value={status}
                    label="Select Status"
                    onChange={(e) => {
                      setstatus(e.target.value);
                    }}
                  >
                    {Statuses?.map((i) => (
                      <MenuItem value={i.id}>{i.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl style={{ width: "150px", marginLeft: "10px" }}>
                  <InputLabel>Select Lender</InputLabel>
                  <Select
                    value={lender}
                    label="Select Lender"
                    onChange={(e) => {
                      setLender(e.target.value);
                    }}
                  >
                    {Lenders?.map((i) => (
                      <MenuItem value={i.id}>{i.value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : null}
          </div>
        </Header>

        <div className="m-5 mt-2">
          <div className="flex flex-col white-card p-8">
            <div className="flex flex flex-wrap w-full justify-between">
              <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                <main>
                  <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative">
                    <div>
                      {/* Table */}
                      <div
                        className="overflow-x-auto"
                        style={{
                          width: window.innerWidth > 1400 ? "80vw" : "70vw",
                        }}
                      >
                        {data === null ? (
                          <Loader size={200} />
                        ) : (
                          <table className="table-auto w-full">
                            {/* Table header */}
                            <thead className="text-xs font-bold uppercase text-black-800 bg-slate-50 border-t border-b border-slate-200">
                              <tr>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left hover:bg-slate-50">
                                    Collection ID
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Folder ID
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Loc Acc ID
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Split amount
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Total amount
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Name
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Lender Name
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Payment ID
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Payment source
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Status
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Txn charge
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Txn Date
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Txn Time
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Bank Lan
                                  </div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">UTR</div>
                                </th>
                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                  <div className="font-bold text-left">
                                    Payment details
                                  </div>
                                </th>
                              </tr>
                            </thead>

                            <tbody className="text-sm divide-y divide-slate-200">
                              {data?.map((customer) => {
                                return (
                                  <tr
                                    className="hover:bg-slate-100"
                                    key={customer.collectionId}
                                  >
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left text-sky-500">
                                        {customer.collectionId}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.folderId}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.locAccountId}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {"₹ " +
                                          parseFloat(
                                            customer?.splitAmount
                                          ).toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                          })}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {"₹ " +
                                          parseFloat(
                                            customer?.totalAmount
                                          ).toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                          })}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.customerName}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.lenderName}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.paymentId}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.paymentSource}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left text-yellow-500">
                                        {customer?.bankStatus}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {"₹ " +
                                          parseFloat(
                                            customer?.transactionCharge
                                          ).toLocaleString("en-IN", {
                                            maximumFractionDigits: 0,
                                          })}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {Moment(
                                          customer.transactionDate
                                        ).format("LL")}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {customer?.transactionTime}
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.bankLan}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="flex items-center">
                                        <div className="font-medium text-slate-500">
                                          {customer?.utr}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                      <div className="text-left">
                                        {JSON.stringify(
                                          customer?.payoutDetails
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        {data?.length === 0 ? (
                          <header className="px-5 py-4">
                            <h2 className="font-semibold text-center text-slate-800">
                              No Matches found
                            </h2>
                          </header>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <div className="m-2">
            <PaginationClassic
              page={parseInt(page)}
              setPage={setPage}
              totalPages={totalPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
